import { configureStore } from '@reduxjs/toolkit'

import emotionalDataReducer from './reducers/emotionalData'

import {emotionalDataListenerMiddleware} from "./middleware/emotionalData";
import marioReducer from "./reducers/marioSlice";
import obstacleReducer from "./reducers/obstacleSlice";
import engineReducer from "./reducers/engineSlice";

export const store = configureStore({
    reducer: {
        emotionalData: emotionalDataReducer,
        mario: marioReducer,
        obstacle: obstacleReducer,
        engine: engineReducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
        .prepend(emotionalDataListenerMiddleware.middleware)
})
