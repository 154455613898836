import "./Sun.css";
import React from "react";

const Sun = () => {
  return (
    <div className="sun-container">
        <div className="sun"></div>
    </div>
  )
}
export default Sun
