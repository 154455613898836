import {
    AVATAR_IDLE_URL,
    AVATAR_IDLE_URL_FEMALE,
    GPT_DEMO_VOICE_TYPE_EN,
    GPT_DEMO_VOICE_TYPE_JP,
    GPT_DEMO_VOICE_TYPE_JP_FEMALE,
    GPT_DEMO_VOICE_TYPE_EN_FEMALE
} from "../../api/remoteConfig";
import {translations} from "../../utils/translations";
const CHAR_LIMIT = 200;

function orderedPromiseRace(promises) {
    return new Promise((resolve, reject) => {
        const results = [];
        let completed = 0;
        let firstResolved = false;

        promises.forEach((promise, index) => {
            promise
                .then((value) => {
                    completed++;
                    results[index] = value;
                    if (index === 0) {
                        firstResolved = true;
                    }

                    if (firstResolved && completed === promises.length) {
                        resolve(results);
                    } else if (firstResolved) {
                        resolve(results.slice(0, completed));
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    });
}
export const talk = (text, gender = 'male') => {

    let chunkedText = splitText(text);
    console.log("chunkedText", chunkedText)
    let promises = chunkedText.map((chunk) => {
        return generateLipsync(chunk, gender)
    })

    return orderedPromiseRace(promises)

}

const generateLipsync = (text, gender) => {
    let voice;

    if(gender === 'male'){
        voice = translations.selectedLocale === 'ja' ? GPT_DEMO_VOICE_TYPE_JP : GPT_DEMO_VOICE_TYPE_EN;
    }else {
        voice = translations.selectedLocale === 'ja' ? GPT_DEMO_VOICE_TYPE_JP_FEMALE || "ja-JP-MayuNeural" : GPT_DEMO_VOICE_TYPE_EN_FEMALE || "en-US-AriaNeural";
    }

    const options = {
        method: 'POST',
        headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            authorization: `Bearer ${process.env.REACT_APP_EX_HUMAN_API_KEY}`
        },
        body: JSON.stringify({
            animation_pipeline: 'high_speed',
            "azure_voice" : voice,
            text: text,
            idle_url: gender === 'male' ? AVATAR_IDLE_URL : AVATAR_IDLE_URL_FEMALE || "https://ugc-idle.s3-us-west-2.amazonaws.com/8e3e687eb248c161b597e872b47451d9.mp4"
        })
    };
    return fetch('https://api.exh.ai/animations/v2/generate_lipsync', options)
        .then(response => response.blob())
}

function splitText(text) {
    const maxLength = CHAR_LIMIT;
    const chunks = [];

    if (text.length <= maxLength) {
        return [text];
    }

    let currentPosition = 0;

    while (currentPosition < text.length) {
        let endPosition = currentPosition + maxLength;

        // Check if the rest of the text is shorter than maxLength
        if (endPosition >= text.length) {
            endPosition = text.length;
        } else {
            // Move the end position back to the last space or period within maxLength
            while (endPosition > currentPosition && text[endPosition] !== ' ' && text[endPosition] !== '.') {
                endPosition--;
            }

            // If no suitable end position is found, force a split at maxLength
            if (endPosition === currentPosition) {
                endPosition = currentPosition + maxLength;
            } else {
                // Move the end position to the next character to include space or period
                endPosition++;
            }
        }

        // Add the chunk to the array
        chunks.push(text.slice(currentPosition, endPosition));

        // Update the current position to the end position
        currentPosition = endPosition;
    }

    return chunks;
}

