import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="copyright">Copyright © {new Date().getFullYear()} {" "}
      POWERED BY SOLO
    </div>
  )
}
export default Footer
