import React, { Component } from 'react';
import styled from 'styled-components'
import _ from 'lodash'
import { PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION } from '../api/remoteConfig';

class ProgressBar extends Component {

    progressLimit = (min, value, max) => {
        return Math.min(Math.max(min, value), max);
    }

    render() {

        const { progressColor, percentage, emptyTracker, width, backgroundColor, label, showValue, value, font, threshold, heightMargin } = this.props
        let height = (width / 3.5) - 10
        if(heightMargin){
            height = height + 10
        }

      //  console.log("threshold", threshold)

        return (
            <Tracker height={height} color={backgroundColor ? backgroundColor : '#D7D7D7'}>
                {!emptyTracker && <ProgressInTracker
                    progress={`${(this.progressLimit(0, percentage, 100) / 100) * 100}%`}
                    color={progressColor}
                    height={height} />}

                {label && <LabelContainer>
                    <Value style={{ fontSize: font ? 15 : 10 }}>{label}</Value>
                </LabelContainer>}

                {showValue && <ValueContainer style={emptyTracker ? { justifyContent: 'center', right: 0, left: 0, marginRight: 0 } : {}}>
                    <Value style={{ fontSize: font ? 15 : 12 }}>{value ? value : percentage}</Value>
                </ValueContainer>}

                {threshold ? <div style={{
                    position: "absolute",
                    height: "100%",
                    width: 2,
                    backgroundColor: "red",
                    zIndex: 999,
                    left: `${(this.progressLimit(0, threshold, 100) / 100) * 100}%`
                }}/> : null}
            </Tracker >
        );
    }
}

const Tracker = styled.div`
    display: flex;
    background: ${props => props.color};
    height: ${props => props.height}px;
    position: relative;
`

const ProgressInTracker = styled.div`
    width: ${props => props.progress};
    height: ${props => props.height}px;
    background: ${props => props.color};
    transition: width ${PLAGROUND_SIDEBAR_VALUE_ANIMATION_DURATION}s;
`

const ValueContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 5px;
    `

const LabelContainer = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    top: 0;
    margin-left: 5px;
    `

const Value = styled.div`
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    text-align: center;
    letter-spacing: -0.30728px;

    color: "#2E384D";
`

export default ProgressBar;
