import React, {Component, Fragment} from 'react';
import _, {get, findIndex} from "lodash";

import colors from "../../styles/colors";
import styled from 'styled-components'

import VideoStream from "../../components/VideoStream";
import {
    initEmotionDetection,
    detectEmotions,
    uploadImage, findMatchingFaceID, generateFaceID, faceIdMap, findMatchingFaceIDLocationBased, filterFaceIdMap
} from "../../utils/bioFeedback/faceApi"
import {load as loadTranslations, translations, setLocale, getString} from "../../utils/translations";
import Switch from "react-switch";

import ProgressBar from '../../components/ProgressBar'
import Widget from '../../components/Widget'
import ValenceEnergyScatter from "../../components/ValenceEnergyScatter"
import Popup from '../../components/Popup';
import OverTimeChart from '../../components/OverTime';

import * as faceapi from "face-api.js";
import {pushToTracker, solo as soloSdkClient} from "../../api/solo-sdk";

import {
    calcStressLevel,
    calcInterestLevel,
    calcEngagementLevel,
    normalizeValue,
    getDominantEmotion, getEmojiFilterValue, getResults
} from "./helpers"

import {
    convertEmotions,
    thresholdEmotion,
    fromBoolToEmotion
} from "../../utils/bioFeedback"

import {connect} from "react-redux";

import {pushToOvertime, resetOvertime, setWidgetResults} from "../../app/reducers/emotionalData";

import {PROGRESS_BARS, PLAYER_TYPE, FACE_API_OPTIONS, OVER_TIME_BUTTONS} from "../../constants";

import Avatar, {AVATAR_SIZE} from "../../components/Avatar";


import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import DetectionSquare from "../../components/DetectionSquare"

import {
    updateConfig,
    MASK_ON_CERTAINTY_THRESHOLD,
    FACE_CERTAINTY_FRAMES, DETECTION_SCORE_THRESHOLD
} from "../../api/remoteConfig"

import {styles} from "../../styles/styleguide";
import {getAsset, imageDimensions, normalizeMoodEnergyChartPoints, videoDimensions} from "../../utils";
import {logout, updateThreshold} from "../../api/api";
import {getThresholds} from "../../api/api";
import Logo from "./Logo";
import {talk} from "../../services/avatar/exH";
import MarioJump from "../../components/MarioJump";
import {marioJumping} from "../../app/reducers/marioSlice";
import EmojiFilter from "../../components/EmojiFilter";
import {setDie, setReady} from "../../app/reducers/engineSlice";
import ContentSelectionPopup from "../../components/ContentSelectionPopup";
import MediaPlayer from "../../components/MediaPlayer";

const options = new faceapi.SsdMobilenetv1Options({minConfidence: 0.3});
const OVERTIME_CHART_HEIGHT = 250;

class App extends Component {

    constructor(props) {
        super(props);

        this.progressBar = React.createRef()
        this.player = React.createRef()
        this.playerRef = React.createRef()
        this.stream = React.createRef()
        this.uploadStream = React.createRef()
        this.chart = React.createRef()
        this.options = React.createRef()
        this.language = React.createRef()
        this.uploadedImage = React.createRef()
        this.uploadedVideo = React.createRef()
        this.videoPlayer = React.createRef()
        this.emotionsSidebar = React.createRef()
        this.avatarRef = React.createRef()
        this.avatarTalking = false

        this.emotionsTracker = [];
        this.moodEnergyTracker = [];
        this.scanTimeOut = null;
        this.detectTimeOut = null;
        this.shouldDetectTimeOut = null;
        this.footerTitleTimeOut = null;
        this.detectionSquare = null;
        this.checkupTimeOut = null;
        this.timeSpent = 0;
        this.forwardTimes = [];
        this.avatarVideoQueue = []
        this.contentFeedbackTime = 0

        this.emojiRefs = {
            happy: React.createRef(),
            sad: React.createRef(),
            surprised: React.createRef(),
            angry: React.createRef(),
            fear: React.createRef(),
            disgusted: React.createRef(),
        }

        this.state = {
            loader: true,
            detectionsArray: [],

            noResultCount: 0,
            timeSpent: 0,
            currentlyDisplayed: {},
            valenceEnergyData: [],
            videoFile: null,
            videoPlaying: false,
            playerType: PLAYER_TYPE.CAMERA,
            input: null,
            showEmotionsBar: true,
            detectedImage: false,
            currentLang: translations.selectedLocale,
            translationsLoaded: true,
            emotionDetectionInitialized: false,
            // isMobile,
            playerPlaying: false,
            playlist: [],
            sampleCount: 0,
            videoSrc: '',
            songDuration: 65,
            playedSeconds: 0,
            checkupPlayedSeconds: 0,
            isScanning: true,
            engagementLevelFade: false,
            interestLevelFade: false,
            stressLevelFade: false,
            updatedWidgets: false,
            optionsOpened: true,
            gotCheckupResults: false,
            videoUploadOption: FACE_API_OPTIONS.SSD,
            videoPopupOpen: false,
            overtime: [],
            selectedButtons: [OVER_TIME_BUTTONS.STRESS],
            happyThreshold: .5,
            calmThreshold: .5,
            sadThreshold: .5,
            angryThreshold: .5,
            disgustThreshold: .5,
            fearThreshold: .5,
            surpriseThreshold: .5,
            faceIdMinDistanceThreshold: 50,
            happyActive: true,
            calmActive: true,
            sadActive: true,
            angryActive: true,
            disgustActive: true,
            fearActive: true,
            surpriseActive: true,
            maskOn: false,
            overtimePatterns: null,
            showFaceLandmarks: false,
            emotionsModelActive: true,
            maskModelActive: false,
            yawnModelActive: true,
            eyesClosedModelActive: true,
            eyesDirectionModelActive: true,
            time: 0,
            fps: 0,
            chartActive: true,
            sidebarActive: true,
            boundingBoxActive: true,
            faceIds: [],
            selectedIds: [],
            faceIdCount: {},
            avatarActive: false,
            avatarHappinessIndex: 1,
            avatarSadnessIndex: 1,
            avatarAngerIndex: 1,
            avatarFearIndex: 1,
            avatarDisgustIndex: 1,
            avatarSurpriseIndex: 1,
            emojiFilterActive: false,
            emojiFrameInfo: {},
            happyReflection: null,
            sadReflection: null,
            surprisedReflection: null,
            angryReflection: null,
            fearReflection: null,
            disgustedReflection: null,
            cplxModelActive: false,
            alertsActive: false,
            contentFeedbackPopupOpen: false,
            contentFeedbackUrl: null,
            contentFeedbackPlaying: false,
            contentFeedbackEnded: false,
        }
    }

    componentDidMount = async () => {
        try {
            const initializedEmotions = await initEmotionDetection()
            this.setState({emotionDetectionInitialized: initializedEmotions})

            const initializedTranslations = await loadTranslations()
            this.setState({
                currentLang: translations.selectedLocale,
                translationsLoaded: true
            })
            if (!initializedTranslations) {
                console.error("error initialize translations")
            }

            if (initializedEmotions && initializedTranslations) {
                this.setState({loader: false})
            }
            console.log("initialized", initializedEmotions && initializedTranslations)

            // await createDefaultThresholds()

        } catch (e) {
            console.error("unable to init emotion detections/translations", e)
        }

        try {

            let {happy, calm, sad, angry, disgust, fear, surprise, modules, faceIdMinDistance} = await getThresholds()

            this.setState({
                happyThreshold: Number(happy),
                calmThreshold: Number(calm),
                sadThreshold: Number(sad),
                angryThreshold: Number(angry),
                disgustThreshold: Number(disgust),
                fearThreshold: Number(fear),
                surpriseThreshold: Number(surprise),
                faceIdMinDistanceThreshold: Number(faceIdMinDistance),
            })
        } catch (e) {
            console.error("unable to get thresholds", e)
        }

        //mirror emoji filters
        for (const emoji in this.emojiRefs) {
            const targetElement = this.emojiRefs[emoji]?.current;
            if (targetElement) {
                const clonedElement = targetElement.cloneNode(true);
                this.setReflection(clonedElement, emoji);
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!prevState.emojiFilterActive && this.state.emojiFilterActive) {
            for (const emoji in this.emojiRefs) {
                const targetElement = this.emojiRefs[emoji]?.current;
                if (targetElement) {
                    const clonedElement = targetElement.cloneNode(true);
                    this.setReflection(clonedElement, emoji);
                }
            }
        }
        if (this.state.playerType !== prevState.playerType) {
            this.setState({gotCheckupResults: false, detectionsArray: []})
            this.props.resetOvertime()
            clearTimeout(this.checkupTimeOut);
            if (prevState.playerType === PLAYER_TYPE.CHECKUP_CAMERA && this.state.playerType === PLAYER_TYPE.CAMERA) {
                this.detectEmotions()
            }
        }

        if (prevState.input !== this.state.input ||
            prevState.videoSrc !== this.state.videoSrc ||
            prevState.videoFile !== this.state.videoFile) {

            this.props.resetOvertime()

            clearTimeout(this.detectTimeOut)
            this.detectTimeOut = null

            clearTimeout(this.shouldDetectTimeOut)
            this.shouldDetectTimeOut = null
        }

        if (this.state.playerType === PLAYER_TYPE.VIDEO_UPLOAD || this.state.playerType === PLAYER_TYPE.VIDEO_PLAYER) {
            if (prevState.playerType !== this.state.playerType ||
                prevState.videoFile !== this.state.videoFile) {
                const video = document.getElementById('myVideo');
                this.setState({input: video})
                video.onplaying = (event) => {
                    // this.setDuration(video.duration)

                    clearTimeout(this.detectTimeOut)
                    this.detectTimeOut = null

                    clearTimeout(this.shouldDetectTimeOut)
                    this.shouldDetectTimeOut = null

                    this.setState({videoPlaying: true})
                    console.log("UPLOADED VIDEO STARTED")
                    if(this.props.sdkUser){
                        console.log("start monitoring", this.state.playerType)
                        soloSdkClient.setDetectionInterval(100)
                        soloSdkClient.setContent(this.state.playerType, true)
                    }
                    this.detectEmotions()
                };
                video.onpause = (event) => {
                    this.setState({videoPlaying: false})
                    if(this.props.sdkUser){
                        soloSdkClient.stopMonitoring()
                    }
                };
                video.onended = (event) => {
                    this.setState({videoPlaying: false})
                    if(this.props.sdkUser){
                        soloSdkClient.stopMonitoring()
                    }
                }
            }
        }

        const current = this.props.widgetResultsArray.filter(w => w.id === this.state.currentlyDisplayed.id)
        const prev = prevProps.widgetResultsArray.filter(w => w.id === prevState.currentlyDisplayed.id)

        const prevEngagement = prev.length ? prev[0].engagement : null
        const currentEngagement = current.length ? current[0].engagement : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevEngagement, currentEngagement)) {

            const prevLevel = calcEngagementLevel(prevEngagement)
            const currentLevel = calcEngagementLevel(currentEngagement)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({engagementLevelFade: true})
                setTimeout(() => {
                    this.setState({engagementLevelFade: false})
                }, 150)
            }
        }

        const prevInterest = prev.length ? prev[0].interest : null
        const currentInterest = current.length ? current[0].interest : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevInterest, currentInterest)) {

            const prevLevel = calcInterestLevel(prevInterest)
            const currentLevel = calcInterestLevel(currentInterest)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({interestLevelFade: true})
                setTimeout(() => {
                    this.setState({interestLevelFade: false})
                }, 150)
            }
        }

        const prevStress = prev.length ? prev[0].stress : null
        const currentStress = current.length ? current[0].stress : null

        if (prevState.currentlyDisplayed &&
            !_.isEqual(prevStress, currentStress)) {

            const prevLevel = calcStressLevel(prevStress)
            const currentLevel = calcStressLevel(currentStress)

            if (!_.isEqual(prevLevel, currentLevel)) {
                this.setState({stressLevelFade: true})
                setTimeout(() => {
                    this.setState({stressLevelFade: false})
                }, 150)
            }
        }

        if (this.state.playerType === PLAYER_TYPE.MARIO_JUMP && this.state.playerType !== prevState.playerType) {
            clearTimeout(this.detectTimeOut)
            this.detectTimeOut = null

            clearTimeout(this.shouldDetectTimeOut)
            this.shouldDetectTimeOut = null

            this.detectEmotions()
        }

        if(this.props.sdkUser && prevState.playerType !== this.state.playerType){
           if([PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD].includes(prevState.playerType)){
               console.log("stop monitoring")
               soloSdkClient.stopMonitoring()
            }
        }



    }

    setReflection = (element, emoji) => {
        this.setState({[`${emoji}Reflection`]: element})
    }

    startTalk = async (text, gender = "female") => {
        try {
            this.avatarTalking = true
            let res = await talk(text, gender)

            if (res && res.length > 0) {
                this.avatarVideoQueue = res
                return new Promise(async (resolve) => {
                    // Get or create the video element
                    this.avatarRef.current.onended = () => {
                        this.onAvatarVideoEnded(resolve)
                    }
                    this.playNextVideo(resolve)
                });
            } else {
                return null
            }
        } catch (e) {
            console.error("error in startTalk", e)
            return null
        }

    }

    onAvatarVideoEnded = (resolve) => {
        this.playNextVideo(resolve)
    }

    playNextVideo = (resolve) => {
        let queue = this.avatarVideoQueue

        if (queue.length > 0) {
            this.avatarRef.current.style.height = 'auto';
            this.avatarRef.current.style.width = AVATAR_SIZE;
            this.avatarRef.current.style.opacity = 1;
            this.avatarRef.current.style.transition = "opacity 1s ease-out";
            const videoUrl = URL.createObjectURL(queue[0]);
            this.avatarRef.current.src = videoUrl
            this.avatarVideoQueue = queue.slice(1)
            this.avatarRef.current.play()
        } else {
            this.avatarTalking = false
            // avatarRef.current.style.height = 0;
            // avatarRef.current.style.width = 0;
            this.avatarRef.current.style.opacity = 0;
            this.avatarRef.current.style.transition = "opacity 1s ease-in";
            // avatarRef.current.src = AVATAR_IDLE_URL;
            resolve && resolve(true)
        }
    }
    getSideBarTitle = () => {
        const {playerType, gotCheckupResults} = this.state
        if (playerType === PLAYER_TYPE.CHECKUP_CAMERA) {
            if (gotCheckupResults) {
                return getString("complete")
            } else {
                return getString("analyzing")
            }
        }
        return getString("samples")
    }

    changeThreshold = async (name, value) => {
        console.log("changeThreshold", name, value)
        this.setState({[`${name}Threshold`]: Number(value)})
        await updateThreshold(name, value)
    }

    changeEmotionActive = (name, value) => {
        console.log("changeEmotionActive", name, value)
        this.setState({
            [`${name}Active`]: value,
            detectedImage: false,
        }, () => {
            console.log("changeEmotionActive", this.state.playerType)
            if (this.state.playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                this.detectEmotions()
            }
        })
    }

    streamStarted = async () => {
        const {input, playerType} = this.state
        console.log("streamStarted", input)
        this.startTimer()
        this.detectEmotions()
        if(this.props.sdkUser){
            console.log("start monitoring", playerType)
            soloSdkClient.setDetectionInterval(100)
            soloSdkClient.setContent(playerType, true)
        }

    }

    startSessionBackupInterval = () => {
        this.sessionBackupInterval = setInterval(() => {
            this.backupSession()
        }, 1000 * 10)
    }

    startTimer = () => {
        // start timer to calculate time spent
        // count every 10 milliseconds
        this.startTime = Date.now()
        this.timer = setInterval(() => {
            let timeSpent = Date.now() - this.startTime
            this.timeSpent = timeSpent
        }, 10)
    }

    pauseTimer = () => {
        clearInterval(this.timer)
    }

    processEmotions = (tracker) => {
        try {
            let convertedEmotions
            if (tracker) {
                convertedEmotions = convertEmotions(tracker);
            } else {
                convertedEmotions = convertEmotions(this.emotionsTracker);
            }
            return {
                valence: convertedEmotions.valence,
                energy: convertedEmotions.energy,
                emotions: convertedEmotions.avg,
                stress: convertedEmotions.stress,
                wellbeing: convertedEmotions.wellbeing,
                interest: convertedEmotions.interest,
                engagement: convertedEmotions.engagement
            }
        } catch (e) {
            console.error("error in processEmotions", e)
        }
    }

    shouldDetectEmotions = () => {
        const {detectedImage, playerType, videoPlaying, gotCheckupResults} = this.state;

        if (playerType === PLAYER_TYPE.IMAGE_UPLOAD && detectedImage) {
            return false
        }
        if (playerType === PLAYER_TYPE.VIDEO_PLAYER || playerType === PLAYER_TYPE.VIDEO_UPLOAD) {
            if (!videoPlaying) {
                return false
            }
        }
        if (playerType === PLAYER_TYPE.CHECKUP_CAMERA && gotCheckupResults) {
            return false
        }

        if(PLAYER_TYPE.CONTENT_FEEDBACK === playerType && (!this.state.contentFeedbackPlaying || this.state.contentFeedbackEnded)){
            return false
        }

        return true
    }

    pushToOverTime = (data) => {
        const {playerType} = this.state
        let timeSpent = playerType === PLAYER_TYPE.CONTENT_FEEDBACK ?  this.contentFeedbackTime : this.timeSpent;
       // console.log("pushToOverTime", data, timeSpent)
        let vidCurrTime = playerType === PLAYER_TYPE.CONTENT_FEEDBACK ?  this.contentFeedbackTime : this.uploadedVideo.current && this.uploadedVideo.current.currentTime;
        this.props.pushToOvertime({
            data,
            playerType,
            vidCurrTime: vidCurrTime,
            timeSpent: timeSpent
        })
    }

    updateTimeStats(timeInMs) {
        this.forwardTimes = [timeInMs].concat(this.forwardTimes).slice(0, 30)
        const avgTimeInMs = this.forwardTimes.reduce((total, t) => total + t) / this.forwardTimes.length
        let time = `${Math.round(avgTimeInMs)} ms`;
        let fps = `${faceapi.utils.round(1000 / avgTimeInMs)}`;
        //console.log('time', time)
        // console.log('fps', fps)
        this.setState({fps, time})
    }

    handleAvatarResponse = async (detection) => {
        const {
            happiness,
            angry,
            disgusted,
            surprised,
            sad,
            fearful
        } = detection

        if (this.avatarTalking || !this.state.avatarActive) {
            return
        }

        if (happiness > 0.9) {
            await this.startTalk(getString(`avatar_response_happy${this.state.avatarHappinessIndex}`))
            if (this.state.avatarHappinessIndex === 3) {
                this.setState({avatarHappinessIndex: 1})
            } else {
                this.setState({avatarHappinessIndex: this.state.avatarHappinessIndex + 1})
            }
        } else if (angry > 0.9) {
            await this.startTalk(getString(`avatar_response_angry${this.state.avatarAngerIndex}`))
            if (this.state.avatarAngerIndex === 3) {
                this.setState({avatarAngerIndex: 1})
            } else {
                this.setState({avatarAngerIndex: this.state.avatarAngerIndex + 1})
            }
        } else if (disgusted > 0.9) {
            await this.startTalk(getString(`avatar_response_disgust${this.state.avatarDisgustIndex}`))
            if (this.state.avatarDisgustIndex === 3) {
                this.setState({avatarDisgustIndex: 1})
            } else {
                this.setState({avatarDisgustIndex: this.state.avatarDisgustIndex + 1})
            }
        } else if (surprised > 0.9) {
            await this.startTalk(getString(`avatar_response_surprised${this.state.avatarSurpriseIndex}`))
            if (this.state.avatarSurpriseIndex === 3) {
                this.setState({avatarSurpriseIndex: 1})
            } else {
                this.setState({avatarSurpriseIndex: this.state.avatarSurpriseIndex + 1})
            }
        } else if (sad > 0.9) {
            await this.startTalk(getString(`avatar_response_sad${this.state.avatarSadnessIndex}`))
            if (this.state.avatarSadnessIndex === 3) {
                this.setState({avatarSadnessIndex: 1})
            } else {
                this.setState({avatarSadnessIndex: this.state.avatarSadnessIndex + 1})
            }
        } else if (fearful > 0.9) {
            await this.startTalk(getString(`avatar_response_fear${this.state.avatarFearIndex}`))
            if (this.state.avatarFearIndex === 3) {
                this.setState({avatarFearIndex: 1})
            } else {
                this.setState({avatarFearIndex: this.state.avatarFearIndex + 1})
            }
        }
    }

    addFaceId = (faceId) => {
        this.setState({faceIds: [...this.state.faceIds, faceId]})
        //auto select the first appearence of face
        if (this.state.selectedIds.length === 0) {
            this.selectId(faceId)
        }
    }

    detectEmotions = async (interval = 0) => {

        //   console.log('detectEmotions interval', interval)

        const {sampleCount, playerType, input, checkupPlayedSeconds} = this.state
        const {
            emotionsModelActive,
            maskModelActive,
            yawnModelActive,
            eyesClosedModelActive,
            eyesDirectionModelActive
        } = this.state
        let widgets

        try {
            if (!this.shouldDetectEmotions()) {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
                return this.shouldDetectTimeOut = setTimeout(() => this.detectEmotions(interval), interval)
            }else {
                clearTimeout(this.shouldDetectTimeOut)
                clearTimeout(this.detectTimeOut)
            }


            const ts = Date.now()
            const result = await detectEmotions(input, options, {
                emotionsModelActive,
                maskModelActive,
                yawnModelActive,
                eyesClosedModelActive,
                eyesDirectionModelActive
            })

            // console.log("onresult", result && result.length)

            this.updateTimeStats(Date.now() - ts)

            if(this.props.sdkUser && soloSdkClient.isMonitoring()){
                pushToTracker({results: result})
            }

            if (result && result.length) {

                const {noResultCount, showFaceLandmarks} = this.state
                let counter = noResultCount
                this.setState({noResultCount: counter - 1})

                if (playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                    this.setState({detectedImage: true})
                }

                this.setState({detectionsArray: []})

                const detections = []
                const widgetResults = []

                let i = 0
                let frameIds = []
                for (const r of result) {

                    let id = playerType === PLAYER_TYPE.IMAGE_UPLOAD ? generateFaceID() : this.getFaceId(r);
                    frameIds.push(id)
                    widgets = {}

                    let score = r.detection.score;

                    if (process.env.REACT_APP_ENV === "lab"  && score < DETECTION_SCORE_THRESHOLD) {
                        console.log("invalid detection: score", score)
                        continue
                    }

                    let maskMode = maskModelActive && r.mask > MASK_ON_CERTAINTY_THRESHOLD
                    this.setState({maskOn: maskMode})

                    if (maskMode) {
                        updateConfig(true)
                    } else {
                        updateConfig(false)
                    }

                    const {
                        happyThreshold,
                        calmThreshold,
                        sadThreshold,
                        angryThreshold,
                        disgustThreshold,
                        fearThreshold,
                        surpriseThreshold,
                        happyActive,
                        calmActive,
                        sadActive,
                        angryActive,
                        disgustActive,
                        fearActive,
                        surpriseActive,

                    } = this.state

                    const {angry, disgusted, fearful, happy, neutral, sad, surprised} = r.expressions

                    const isHappy = happyActive ? thresholdEmotion(happy, happyThreshold) : false
                    const isCalm = calmActive ? thresholdEmotion(neutral, calmThreshold) : false
                    const isSad = sadActive ? thresholdEmotion(sad, sadThreshold) : false
                    const isAngry = angryActive ? thresholdEmotion(angry, angryThreshold) : false
                    const isDisgusted = disgustActive ? thresholdEmotion(disgusted, disgustThreshold) : false
                    const isFearful = fearActive ? thresholdEmotion(fearful, fearThreshold) : false
                    const isSurprised = surpriseActive ? thresholdEmotion(surprised, surpriseThreshold) : false

                    let thresholdEmotions = {
                        happiness: maskMode ? fromBoolToEmotion(isHappy) : happy,
                        neutral: maskMode ? fromBoolToEmotion(isCalm) : neutral,
                        angry: maskMode ? fromBoolToEmotion(isAngry) : disgusted,
                        disgusted: maskMode ? fromBoolToEmotion(isDisgusted) : angry,
                        surprised: maskMode ? fromBoolToEmotion(isSurprised) : surprised,
                        sad: maskMode ? fromBoolToEmotion(isSad) : sad,
                        fearful: maskMode ? fromBoolToEmotion(isFearful) : fearful
                    }

                    this.handleAvatarResponse(thresholdEmotions)

                    //console.log("thresholdEmotions", thresholdEmotions)

                    widgets = getResults(id, thresholdEmotions)

                    widgets = {
                        ...widgets,
                        mask: r.mask,
                        noMask: r.noMask,
                        yawn: r.yawn,
                        yawnRatio: r.yawnRatio,
                        headPose: r.headPose,
                        frown: r.frown,
                        closedLeftEye: r.closedLeftEye,
                        openLeftEye: r.openLeftEye,
                        closedRightEye: r.closedRightEye,
                        openRightEye: r.openRightEye,
                        closedEyes: r.closedEyes,
                        openEyes: r.openEyes,
                        score: score,
                        time: ts
                    }
                    widgetResults.push(widgets)

                    if (playerType !== PLAYER_TYPE.IMAGE_UPLOAD) {
                        this.moodEnergyTracker.push({
                            emotions: r.expressions,
                            playedSeconds: this.state.playedSeconds,
                            songDuration: this.state.songDuration,
                            thresholdEmotions,
                            mask: r.mask,
                            noMask: r.noMask,
                            yawn: r.yawn,
                            yawnRatio: r.yawnRatio,
                            headPose: r.headPose,
                            frown: r.frown,
                            closedLeftEye: r.closedLeftEye,
                            openLeftEye: r.openLeftEye,
                            closedRightEye: r.closedRightEye,
                            openRightEye: r.openRightEye,
                            closedEyes: r.closedEyes,
                            openEyes: r.openEyes,
                        }) //remote
                        const {valence, energy} = this.processEmotions(this.moodEnergyTracker)
                        r.valence = valence
                        r.energy = energy
                    }

                    if (PLAYER_TYPE.MARIO_JUMP === playerType) {

                        if (happy > .5) {
                            this.jump()
                        }
                    }

                    r.id = id

                    this.setState({sampleCount: sampleCount + 7})
                    detections.push(r)
                }

                filterFaceIdMap(frameIds)

                this.setState({currentResults: result})

                this.pushToOverTime(widgetResults)

                // handle face landmarks
                if ([PLAYER_TYPE.VIDEO_UPLOAD, PLAYER_TYPE.CAMERA, PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType)) {
                    if (showFaceLandmarks) {
                        const video = document.getElementById('videoElement') || document.getElementById('myVideo')

                        const displaySize = videoDimensions(video)

                        if (video && video.offsetWidth) {
                            //  console.log("displaySize", displaySize)
                            // resize the overlay canvas to the input dimensions
                            const canvas = document.getElementById('canvas-overlay')
                            faceapi.matchDimensions(canvas, displaySize)
                            // resize the detected boxes and landmarks in case your displayed image has a different size than the original
                            const resizedResults = faceapi.resizeResults(result, displaySize)
                            // console.log("resizedResults", resizedResults)
                            // draw the landmarks into the canvas

                            console.log("resizedResults", resizedResults)
                            faceapi.draw.drawFaceLandmarks(canvas, resizedResults)

                            function drawFaceLandmarksIndexes(canvas, resizedResults) {
                                const ctx = canvas.getContext("2d");

                                // Set text color and font
                                ctx.fillStyle = "red";
                                ctx.font = "12px Arial";

                                // Loop through each landmark
                                resizedResults.forEach((resizedResult, index) => {

                                    let landmarks = resizedResult.landmarks.positions;
                                    landmarks.forEach((landmark, index) => {
                                        // console.log("landmark", landmark)
                                        // Draw a circle at each landmark point
                                        ctx.beginPath();
                                        ctx.arc(landmark._x, landmark._y, 2, 0, 2 * Math.PI);
                                        ctx.fill();

                                        // Display the array index next to each landmark
                                      //  ctx.fillText(index.toString(), landmark._x + 5, landmark._y + 5);
                                    })

                                });
                            }

                            drawFaceLandmarksIndexes(canvas, resizedResults)
                        }
                    }
                } else if (playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                    this.props.setWidgetResults(widgetResults)
                    if (showFaceLandmarks) {
                        this.showImageLandmarks();
                    }
                }

                let showEmotionsBar = true
                if ((playerType !== PLAYER_TYPE.IMAGE_UPLOAD || PLAYER_TYPE.MARIO_JUMP === playerType) && detections.length > 1) {
                    showEmotionsBar = false
                }

                if (playerType === PLAYER_TYPE.CHECKUP_CAMERA) {
                    if (this.state.gotCheckupResults) {
                        showEmotionsBar = true
                    } else {
                        showEmotionsBar = false
                    }
                }

                const valenceEnergyData = this.calcValenceEnergyData(widgetResults)

                let currentlyDisplayed = {...this.state.currentlyDisplayed}
                if (detections.length > 0) {
                    currentlyDisplayed = {
                        ...currentlyDisplayed,
                        ['expressions']: detections[0].expressions,
                        ['detection']: detections[0].detection,
                        ['id']: detections[0].id,
                        ['moodTitle']: detections[0].moodTitle
                    }
                }



                this.setState({
                    detectionsArray: detections,
                    showEmotionsBar,
                    currentlyDisplayed,
                    valenceEnergyData
                })

            } else {
                let {noResultCount} = this.state
                let counter = noResultCount

                if (playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
                    this.setState({detectionsArray: [], currentlyDisplayed: {}, valenceEnergyData: [{x: 0, y: 0}]})
                } else {
                    this.setState({noResultCount: counter + 1})
                    if (counter > 3) {
                        this.setState({
                            detectionsArray: [],
                            currentlyDisplayed: {},
                            valenceEnergyData: [{x: 0, y: 0}],
                            noResultCount: 0
                        })
                    }
                }

                let overtimeData = [{
                    valence: null,
                    mood: null,
                    energy: null,
                    stress: null,
                    wellbeing: null,
                    interest: null,
                    engagement: null,
                    stressLevel: null,
                    interestLevel: null,
                    engagementLevel: null,
                    wellbeingLevel: null,
                    valenceLevel: null,
                    energyLevel: null,
                    id: null
                }]

                this.pushToOverTime([overtimeData])



                // console.log("no result")
            }
            if (this.shouldDetectEmotions()) {
                this.detectTimeOut = setTimeout(() => this.detectEmotions(interval), interval)
            }
        } catch (e) {
            console.log("error", e)
        }
    }

    showImageLandmarks() {

        console.log("showImageLandmarks")

        const {showFaceLandmarks, currentResults} = this.state

        let image = document.getElementById('myImage')

        if (image && image.offsetWidth) {
            let displaySize = imageDimensions(image)
            //  console.log("displaySize", displaySize)
            // resize the overlay canvas to the input dimensions
            const canvas = document.getElementById('canvas-overlay')
            faceapi.matchDimensions(canvas, displaySize)
            // resize the detected boxes and landmarks in case your displayed image has a different size than the original
            const resizedResults = faceapi.resizeResults(currentResults, displaySize)
            // console.log("resizedResults", resizedResults)
            // draw the landmarks into the canvas
            faceapi.draw.drawFaceLandmarks(canvas, resizedResults)

            function drawFaceLandmarksIndexes(canvas, resizedResults) {
                const ctx = canvas.getContext("2d");

                // Set text color and font
                ctx.fillStyle = "red";
                ctx.font = "12px Arial";

                // Loop through each landmark
                resizedResults.forEach((resizedResult, index) => {

                    let landmarks = resizedResult.landmarks.positions;
                    landmarks.forEach((landmark, index) => {
                        // console.log("landmark", landmark)
                        // Draw a circle at each landmark point
                        ctx.beginPath();
                        ctx.arc(landmark._x, landmark._y, 2, 0, 2 * Math.PI);
                        ctx.fill();

                        // Display the array index next to each landmark
                        ctx.fillText(index.toString(), landmark._x + 5, landmark._y + 5);
                    })

                });
            }

            //  drawFaceLandmarksIndexes(canvas, resizedResults)
        }

    }

    jump = () => {
       // console.log("jump")
        const {playerType} = this.state
        if (playerType === PLAYER_TYPE.MARIO_JUMP) {
      //      console.log("jumping", {reloading: this.props.marioReloading, ready: this.props.marioReady})
            if (this.props.marioReady){
                this.props.marioJumping(true)
            }

        }
    }

    getFaceId(r) {
        let descriptor = r.descriptor
        //  console.log("r", r)
        const matchingFaceID = findMatchingFaceIDLocationBased(r.detection.box, this.state.faceIdMinDistanceThreshold);
        let id;

        if (matchingFaceID) {
            // If a match is found, assign the same face ID to the current face
            id = matchingFaceID
        } else {
            // If no match is found, generate a new face ID and add it to the list of detected face IDs
            id = generateFaceID();
            this.addFaceId(id)
        }
        faceIdMap.set(id, r.detection.box);

        if (!this.state.faceIdCount[id]) {
            this.setState({faceIdCount: {...this.state.faceIdCount, [id]: 1}})
        } else {
            this.setState({faceIdCount: {...this.state.faceIdCount, [id]: this.state.faceIdCount[id] + 1}})
        }
        return id;
    }

    updateInput = (input) => {
        this.setState({input})
    }

    calcValenceEnergyData = (detectionsArray) => {
        let data = []
        for (const detection of detectionsArray) {
            data.push({
                x: normalizeValue(detection.valence),
                y: normalizeValue(detection.energy),
                id: detection.id
            })
        }

        return normalizeMoodEnergyChartPoints(data)
    }

    uploadImage = async () => {
        // this.setState({optionsOpened: false})

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.setState({
            playerType: PLAYER_TYPE.IMAGE_UPLOAD,
            detectedImage: false,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}]
        }, async () => {
            await uploadImage()
            this.setState({
                input: document.getElementById('myImage')
            })
            this.detectEmotions()
        })

    }

    uploadVideoPopUp = async () => {
        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.uploadVideo()
    }

    uploadVideo = async () => {
        const videoFile = URL.createObjectURL(document.getElementById('videoUpload').files[0])
        this.setState({
            playerType: PLAYER_TYPE.VIDEO_UPLOAD,
            detectionsArray: [],
            videoFile,
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}]
        })
    }

    openContentFeedback = () => {
        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.setState({
            playerType: PLAYER_TYPE.CONTENT_FEEDBACK,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
            contentFeedbackPopupOpen: true,
            contentFeedbackUrl: null,
            contentFeedbackEnded: false,

        })
    }

    handleCameraClick = () => {
        //   this.setState({optionsOpened: false})

        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.setState({
            playerType: PLAYER_TYPE.CAMERA,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
        })
    }

    handleMarioClick = () => {
        clearTimeout(this.detectTimeOut)
        this.detectTimeOut = null

        clearTimeout(this.shouldDetectTimeOut)
        this.shouldDetectTimeOut = null

        this.setState({
            playerType: PLAYER_TYPE.MARIO_JUMP,
            detectionsArray: [],
            sampleCount: 0,
            valenceEnergyData: [{x: 0, y: 0}],
            optionsOpened: false,
        })
    }

    toggleAvatar = () => {
        this.setState({avatarActive: !this.state.avatarActive})
    }

    toggleEmoji = () => {
        this.setState({emojiFilterActive: !this.state.emojiFilterActive})
    }

    toggleCplxModel = () => {
        this.setState({cplxModelActive: !this.state.cplxModelActive})
    }

    toggleAlerts = () => {
        this.setState({alertsActive: !this.state.alertsActive})
    }

    renderChart = () => {

        let {currentLang, valenceEnergyData, currentlyDisplayed, showEmotionsBar} = this.state

        const width = _.get(this.emotionsSidebar, "current.clientWidth", 179)

        return (
            <div ref={this.chart} style={{
                zIndex: 55,
                height: width + 6,
                width: width,
                backgroundColor: 'white',
                borderRadius: 6.34215,
                paddingBottom: 53,
                paddingLeft: 25,
                paddingRight: 22,
                paddingTop: 7,
                boxShadow: '0px 0px 8.95362px rgba(0, 0, 0, 0.25)',
                opacity: 1
            }}>
                <div style={{
                    fontFamily: 'Rubik',
                    fontWeight: 900,
                    textAlign: 'center',
                    color: '#2E384D',
                    margin: 3,
                }}>
                    {getString("circumplex_model")}
                </div>
                <ValenceEnergyScatter
                    data={valenceEnergyData}
                    currentLang={currentLang}
                    chartStyle={{marginLeft: currentLang === "ja" ? -10 : 0}}
                    size={width}
                />
            </div>
        )
    }

    renderOptions = () => {

        const {currentLang, optionsOpened} = this.state
        const width = this.options.current && this.options.current.offsetWidth

        let divDisplay = "flex"

        return (
            <Col md={1} ref={this.options} style={{
                position: 'absolute',
                left: 10,
                top: 110,
                marginBottom: 35,
                display: 'flex',
                flexDirection: 'column',
                gap: width / 25,
                color: colors.white,
                alignItems: 'center',
                zIndex: 99999
            }}>
                <Fragment>
                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }} onClick={this.handleCameraClick}>{getString("monitoring")}</div>


                    <label style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}
                    >
                        <input
                            id="videoUpload"
                            type="file"
                            onChange={this.uploadVideoPopUp}
                            style={{display: 'none'}}/>
                        {getString("video")}</label>

                    <label style={{
                        width,
                        marginTop: -7,
                        marginBottom: 2,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}>
                        <input id="imageUpload"
                               type="file"
                               onChange={this.uploadImage}
                               style={{display: 'none'}}
                               accept=".jpg, .jpeg, .png"/>
                        {getString("image")}</label>


                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }} onClick={this.openContentFeedback}>{getString("content_feedback")}</div>

                    {process.env.REACT_APP_ENV=== "lab" ? (
                        <div style={{
                            width,
                            height: width / 3,
                            backgroundColor: colors.blue,
                            borderRadius: 10,
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            fontSize: 12,
                            fontWeight: 600,
                            display: divDisplay
                        }} onClick={this.handleMarioClick}>{getString("mario")}</div>
                    ) : null}



                    <div style={{
                        width,
                        height: width / 3,
                        backgroundColor: colors.blue,
                        borderRadius: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                        fontSize: 12,
                        fontWeight: 600,
                        display: divDisplay
                    }}
                         onClick={this.toggleOptions}>{optionsOpened ? getString('hide_options') : getString("options")}</div>
                </Fragment>
            </Col>
        )
    }

    toggleOptions = () => {
        this.setState({optionsOpened: !this.state.optionsOpened})
    }

    toggleFaceLandmarks = () => {
        if (this.state.showFaceLandmarks) {
            const canvas = document.getElementById('canvas-overlay')
            faceapi.matchDimensions(canvas, {width: 0, height: 0})
            // resize the detected boxes and landmarks in case your displayed image has a different size than the original
            // const resizedResults = faceapi.resizeResults(result, displaySize)
            // draw the landmarks into the canvas
            //  faceapi.draw.drawFaceLandmarks(canvas, resizedResults)
        } else if (this.state.playerType === PLAYER_TYPE.IMAGE_UPLOAD) {
            this.showImageLandmarks();
        }
        this.setState({showFaceLandmarks: !this.state.showFaceLandmarks})
    }

    handleDetectionSquareClick = (detection) => {
        this.setState({currentlyDisplayed: detection})
    }

    extendedSquare = () => {
        const {detectionsArray, playerType} = this.state
        if (detectionsArray.length > 1
            || playerType === PLAYER_TYPE.IMAGE_UPLOAD
            || playerType === PLAYER_TYPE.VIDEO_PLAYER
            || playerType === PLAYER_TYPE.VIDEO_UPLOAD
            || playerType === PLAYER_TYPE.CAMERA
        ) {
            return true
        }
        return false
    }

    renderDetectionSquare = (height, width) => {

        const {
            detectionsArray,
            currentlyDisplayed,
            showEmotionsBar,
            playerType,
            boundingBoxActive,
            emojiFilterActive,
            alertsActive
        } = this.state
        const {widgetResultsArray} = this.props
        if (!boundingBoxActive) {
            return null
        }
        let getBiggerFont = false

        if (detectionsArray.length === 1) {
            getBiggerFont = true;
        }

        return (
            detectionsArray.map(d => {
                    let dominantEmotion = getDominantEmotion(d)
                    //   console.log("dominantEmotion", dominantEmotion)

                    let emphasizeDetection = false
                    if (d.id === currentlyDisplayed.id && showEmotionsBar || playerType !== PLAYER_TYPE.IMAGE_UPLOAD) {
                        emphasizeDetection = true
                    }

                    let currentWidget = widgetResultsArray.filter(w => w.id === d.id)
                    let stressLevel, interestLevel, engagementLevel, wellbeingLevel
                    let alerts = null

                    // console.log("currentWidget", currentWidget)

                    if (currentWidget.length) {
                        stressLevel = currentWidget[0].stressLevel
                        interestLevel = currentWidget[0].interestLevel
                        engagementLevel = currentWidget[0].engagementLevel
                        wellbeingLevel = currentWidget[0].wellbeingLevel
                        alerts = currentWidget[0].alerts
                    }else {
                        return null;
                    }


                    let width1 = d.detection._box._width / (d.detection.imageDims._width / width);
                    let emojiFilterValue = getEmojiFilterValue(dominantEmotion);
                    let emojiSize = width1 * 1.25;

                    let top = (d.detection._box.top / (d.detection.imageDims._height / height)) - 7;
                    let left = d.detection._box.left / (d.detection.imageDims._width / width);
                    return <div key={d.id} onClick={() => this.handleDetectionSquareClick(d)} style={{cursor: 'pointer'}}>
                        {emojiFilterActive && emojiFilterValue && (
                            <div key={emojiFilterValue}
                                 style={{position: "absolute", left: left, top: top - width1, width: emojiSize, zIndex: 5}}>
                                <EmojiFilter emotion={emojiFilterValue} width={emojiSize}
                                             updateFrameInfo={this.updateEmojiFrameInfo}
                                             frameInfo={this.state.emojiFrameInfo[d.id]}
                                            id={d.id}
                                />
                            </div>
                        )}
                        <DetectionSquare
                            font={getBiggerFont}
                            emphasize={emphasizeDetection}
                            percentage={dominantEmotion.value}
                            label={getString(dominantEmotion.label)}
                            progressColor={dominantEmotion.color}
                            top={top}
                            left={left}
                            width={width1}
                            height={(d.detection._box._height / (d.detection.imageDims._height / height)) + 25}
                            extendedDetectionSquare={this.extendedSquare() ? true : false}
                            stressLevel={stressLevel}
                            interestLevel={interestLevel}
                            engagementLevel={engagementLevel}
                            wellbeingLevel={wellbeingLevel}
                            mask={currentWidget[0].mask}
                            noMask={currentWidget[0].noMask}
                            yawn={currentWidget[0].yawn}
                            noYawn={currentWidget[0].noYawn}
                            yawnRatio={currentWidget[0].yawnRatio}
                            closedEyes={currentWidget[0].closedEyes}
                            openEyes={currentWidget[0].openEyes}
                            closedRightEye={currentWidget[0].closedRightEye}
                            closedLeftEye={currentWidget[0].closedLeftEye}
                            headPose={currentWidget[0].headPose}
                            frown={currentWidget[0].frown}
                            alerts={alerts}
                            alertsActive={alertsActive}
                            id={d.id}
                            showId={playerType !== PLAYER_TYPE.IMAGE_UPLOAD}
                            score={d.detection.score}
                        />
                    </div>
                }
            )
        )
    }

    updateEmojiFrameInfo = (frameInfo, emotion, id) => {
        this.setState({emojiFrameInfo: {...this.state.emojiFrameInfo, [id]: {...frameInfo, emotion}}})
    }

    renderEmotionsBar = () => {

        const {currentLang, currentlyDisplayed, showEmotionsBar, playerType, maskOn, maskModelActive} = this.state
        let checkup = playerType === PLAYER_TYPE.CHECKUP_CAMERA ? true : false


        return (
            <div style={styles.emotionsSideBar}>
                <div style={{width: "100%"}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "col",
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'black',
                        fontFamily: "Open Sans",
                        fontWeight: 800,
                        letterSpacing: -0.5,
                        paddingTop: 3,
                        paddingBottom: 3,
                        fontSize: currentLang === "ja" && 13,
                        textTransform: "uppercase"
                    }}>
                        {this.getSideBarTitle()}</div>
                    <div style={{paddingLeft: 15, paddingRight: 15}}>
                        <ProgressBar
                            percentage={checkup ? this.state.checkupPlayedSeconds * 10 : get(this.state, "sampleCount", 0)}
                            progressColor={checkup && colors.lipstick}
                            value={checkup && Math.round(this.state.checkupPlayedSeconds)}
                            emptyTracker={checkup ? false : true}
                            showValue={true}
                            width={this.progressBar.current && this.progressBar.current.offsetWidth / 1.3}/>
                    </div>
                </div>
                {PROGRESS_BARS.map(pb => {

                        return (
                            <div style={{width: "100%"}} ref={this.progressBar} key={pb.title}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: "col",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'black',
                                    fontFamily: "Open Sans",
                                    fontWeight: 800,
                                    letterSpacing: -0.5,
                                    paddingTop: 3,
                                    paddingBottom: 3,
                                    fontSize: currentLang === "ja" && 13,
                                }}>
                                    {getString(pb.title)}</div>
                                <div style={{paddingLeft: 15, paddingRight: 15}}>
                                    <ProgressBar
                                        percentage={!showEmotionsBar ? 0 : Math.round(get(currentlyDisplayed.expressions, pb.emotion, 0) * 100)}
                                        progressColor={pb.color}
                                        showValue={!showEmotionsBar ? false : true}
                                        width={this.progressBar.current && this.progressBar.current.offsetWidth / 1.3}
                                        threshold={maskModelActive ? this.state[`${pb.title}Threshold`] * 100 : false}
                                    />
                                </div>
                                {maskModelActive && (
                                    <div style={{paddingLeft: 15, paddingRight: 15, display: "flex", flexDirection: "row"}}>
                                        <input style={{width: "90%"}} value={this.state[`${pb.title}Threshold`]}
                                               type={"number"}
                                               name={`${pb.title}_threshold`} onChange={(e) => {
                                            this.changeThreshold(pb.title, e.target.value)
                                        }}></input>
                                        <Switch checked={maskOn} onChange={(active) => {
                                            this.changeEmotionActive(pb.title, active)
                                        }}></Switch>
                                    </div>
                                )}

                            </div>)
                    }
                )}


            </div>
        )
    }

    tooltipLabelFormatter = (payload, data, x) => {
        if (data[payload] && data[payload].metadata) {
            return data[payload].metadata.title
        }
        if (x.formatter) {
            return x.formatter(payload)
        }
        return payload;
    }

    onOvertimeEmotionClick = (emotion) => {

        const {selectedButtons} = this.state

        let i = findIndex(selectedButtons, (value) => {
            return value === emotion
        });

        let update = _.cloneDeep(selectedButtons)

        if (!selectedButtons.length) {
            update.push(emotion)
        } else {
            if (i === -1) {
                update = [emotion]
            } else {
                update.splice(i, 1);
            }
        }

        this.setState({selectedButtons: update})
    }

    renderOvertimeButtons = (buttons) => {

        const {selectedButtons} = this.state
        let isJP = translations.selectedLocale === "ja"
        let extraStyle = {}
        if (isJP) {
            extraStyle = {fontSize: 10}
        }

        return <div style={{gap: 10, display: 'flex', flexDirection: 'column'}}>
            {
                Object.keys(buttons).map((key, i) =>
                    <OverTimeButton
                        style={Object.assign(selectedButtons.includes(buttons[key]) ? {backgroundColor: colors.blue} : {}, extraStyle)}
                        onClick={() => {
                            this.onOvertimeEmotionClick(buttons[key])
                        }}>
                        {getString(buttons[key])}
                    </OverTimeButton>)
            }
        </div>
    }

    renderIdsButtons = (ids) => {

        const {selectedIds, faceIdCount, showFaceLandmarks} = this.state
        let isJP = translations.selectedLocale === "ja"
        let extraStyle = {}
        if (isJP) {
            extraStyle = {fontSize: 10}
        }

        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: "space-between", width: "100%"}}>

            <div style={{gap: 15, display: 'flex', flexDirection: 'row',}}>
                <OverTimeButton style={{backgroundColor: colors.black, color: colors.white, borderRadius: 0}}
                                onClick={() => {
                                    faceIdMap.clear()
                                    this.props.resetOvertime()
                                    this.setState({faceIds: [], faceIdCount: {}, selectedIds: []})
                                }}>
                    {getString("reset_data")}
                </OverTimeButton>
                {
                    ids.map((key, i) => {
                        if (faceIdCount[key] >= FACE_CERTAINTY_FRAMES) {
                            return (
                                <OverTimeButton
                                    style={Object.assign(selectedIds.includes(key) ? {
                                        backgroundColor: colors.lipstick,
                                        borderRadius: 0
                                    } : {borderRadius: 0}, extraStyle)}
                                    onClick={() => {
                                        this.selectId(key)
                                    }}>
                                    {key}
                                </OverTimeButton>
                            )
                        }

                        return null
                    })
                }
            </div>


            <OverTimeButton style={{backgroundColor: colors.black, color: colors.white, borderRadius: 0, width: 150}}
                            onClick={this.toggleFaceLandmarks}>
                {showFaceLandmarks ? getString("hide_landmarks") : getString("show_landmarks")}
            </OverTimeButton>

        </div>
    }

    selectId = (id) => {
        const {selectedIds} = this.state

        let i = findIndex(selectedIds, (value) => {
            return value === id
        });

        let update = _.cloneDeep(selectedIds)

        if (!selectedIds.length) {
            update.push(id)
        } else {
            if (i === -1) {
                update.push(id)
            } else {
                update.splice(i, 1);
            }
        }

        //  console.log("update", update)

        this.setState({selectedIds: update})
    }

    renderVideo = (width, height) => {

        const {videoFile, detectionsArray, videoSrc, playerType, showFaceLandmarks} = this.state

        let formattedHeight = height
        let isContentFeedback = playerType === PLAYER_TYPE.CONTENT_FEEDBACK;

        if ([PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD].includes(playerType)) {
            formattedHeight = height - OVERTIME_CHART_HEIGHT
        }

        let vid = document.getElementById('myVideo')
        if(PLAYER_TYPE.CAMERA === playerType || isContentFeedback){
            vid = document.getElementById('videoElement')
        }

        let CONTENT_FEEDBACK_CAM_TOP = 50;
        let CONTENT_FEEDBACK_CAM_RIGHT = 10;

        let canvasLeft = 0;
        let canvasTop = 0;
        let vidDims = {}

        if (vid) {
            let {width, height} = videoDimensions(vid)
            canvasLeft = isContentFeedback ? CONTENT_FEEDBACK_CAM_RIGHT :(vid.offsetWidth - width) / 2
            canvasTop = isContentFeedback ? CONTENT_FEEDBACK_CAM_TOP :(vid.offsetHeight - height) / 2
            vidDims = {width, height, offsetWidth: vid.offsetWidth, offsetHeight: vid.offsetHeight}
        }


        return (
            <div style={{
                width: "100%", height: "100%", flexDirection: 'column',
                position: "relative", display: 'flex', alignItems: 'center',
            }}>

                {
                    [PLAYER_TYPE.VIDEO_PLAYER, PLAYER_TYPE.VIDEO_UPLOAD].includes(playerType) && document.getElementById('videoUpload') && document.getElementById('videoUpload').files.length ?

                        <video
                            id="myVideo"
                            style={{width: "100%", height: "auto", maxHeight: formattedHeight}}
                            controls
                            autoPlay={true}
                            ref={this.uploadedVideo}
                            src={playerType === PLAYER_TYPE.VIDEO_PLAYER ? videoSrc : videoFile}
                        /> : null
                }

                {[PLAYER_TYPE.CAMERA].includes(playerType) ?
                <VideoStream
                    streamSuccessHandler={this.streamStarted}
                    width={width}
                    maxHeight={formattedHeight ? formattedHeight : "100vh"}
                    updateInput={this.updateInput}
                /> : null
                }

                {isContentFeedback || playerType === PLAYER_TYPE.MARIO_JUMP ? (<>
                    <div style={{position: "absolute", top: CONTENT_FEEDBACK_CAM_TOP, right: CONTENT_FEEDBACK_CAM_RIGHT, zIndex: 9999}}>
                        <VideoStream
                            streamSuccessHandler={()=>{}}
                            width={width}
                            maxHeight={formattedHeight ? formattedHeight : "100vh"}
                            updateInput={this.updateInput}
                        />
                    </div>

                    {this.renderContentFeedback(window.innerHeight - OVERTIME_CHART_HEIGHT)}
                </>) : (null)}
                <canvas id={"canvas-overlay"} style={{position: "absolute", top: canvasTop, [isContentFeedback ? "right" : "left"]: canvasLeft, width: vidDims.width, height: vidDims.height, zIndex: 99999}}/>

                <div style={{position: 'absolute',zIndex: 9999, top: canvasTop, [isContentFeedback ? "right" : "left"]: canvasLeft, width: vidDims.width, height: vidDims.height}}>
                    {detectionsArray.length ? this.renderDetectionSquare(vidDims.height, vidDims.width) : null}
                </div>

                {[PLAYER_TYPE.CAMERA, PLAYER_TYPE.VIDEO_UPLOAD, PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType) && this.renderOvertimeChart(width)}
                {playerType === PLAYER_TYPE.MARIO_JUMP && (<MarioJump/>)}

            </div>
        )
    }

    onBrushChange = (e) => {
        const {overtime} = this.props
        console.log("onBrushChange", e, _.get(overtime, `[${e.endIndex}].timeSpent`))
        console.log(this.playerRef)

        this.playerRef.seekTo( _.get(overtime, `[${e.endIndex}].timeSpent`), "seconds")
    }

    renderOvertimeChart = (width) => {

        const {selectedButtons, chartActive, faceIds, selectedIds, contentFeedbackEnded, playerType} = this.state
        const {overtime} = this.props

        if (!chartActive) {
            return null
        }
        let modified, shouldModify = false,
            domain = [0, 100]

        shouldModify = true
        modified = _.cloneDeep(overtime)
        let chartWidth = width;

        if (selectedButtons.length === 1) {
            if (selectedButtons[0] === OVER_TIME_BUTTONS.YAW_ANGLE) {
                domain = [-90, 90]
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.PITCH_ANGLE) {
                domain = [-90, 90]
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN) {
                domain = [50, 200]
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.YAWN_RATIO) {
                domain = [0, 100]
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_SCORE) {
                domain = [0, 100]
            } else if (selectedButtons[0] === OVER_TIME_BUTTONS.FROWN_NOSE) {
                domain = [300, 350]
            }
        }

        return (
            <div style={{height: OVERTIME_CHART_HEIGHT, position: "absolute", left: 0, right: 0, bottom: 0}}>
                <div style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 12,
                    flexDirection: 'column'
                }}>
                    <div style={{display: "flex", alignItems: "flex-start", width: "100%"}}>
                        {this.renderIdsButtons(faceIds)}
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        marginTop: 10

                    }}>
                        {this.renderOvertimeButtons([OVER_TIME_BUTTONS.MOOD, OVER_TIME_BUTTONS.ENERGY, OVER_TIME_BUTTONS.STRESS, OVER_TIME_BUTTONS.ENGAGEMENT])}
                        <div style={{
                            width: '100%',
                            height: '100%',
                        }}>
                            <OverTimeChart
                                x={{key: "index"}}
                                y={{key: "value"}}
                                data={shouldModify ? modified : (overtime || [{}])}
                                selectedEmotions={selectedButtons}
                                tooltipLabelFormatter={this.tooltipLabelFormatter}
                                domain={domain}
                                selectedIds={selectedIds}
                                showBrush={playerType === PLAYER_TYPE.CONTENT_FEEDBACK && contentFeedbackEnded}
                                onBrushChange={this.onBrushChange}
                            />
                        </div>
                        {this.renderOvertimeButtons([OVER_TIME_BUTTONS.YAWN_RATIO, OVER_TIME_BUTTONS.EYES, OVER_TIME_BUTTONS.YAW_ANGLE, OVER_TIME_BUTTONS.PITCH_ANGLE,  OVER_TIME_BUTTONS.FROWN])}
                    </div>

                </div>
            </div>
        )
    }

    renderImageUpload = (width, height) => {

        const {showFaceLandmarks} = this.state
        const {detectionsArray} = this.state

        const imageWidth = this.uploadedImage.current && this.uploadedImage.current.offsetWidth
        const imageHeight = this.uploadedImage.current && this.uploadedImage.current.offsetHeight

        const detectionSquareTop = this.uploadedImage.current && this.uploadedImage.current.offsetTop
        const detectionSquareLeft = this.uploadedImage.current && this.uploadedImage.current.offsetLeft


        let image = document.getElementById('myImage')
        let canvasLeft = 0;
        let canvasTop = 0;
        let imageDims = {}
        if (image) {
            let {width, height} = imageDimensions(image)
            canvasLeft = (image.offsetWidth - width) / 2
            canvasTop = (image.offsetHeight - height) / 2
            imageDims = {width, height}

        }

        return (

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: "relative"}}>
                <img id="myImage" src="" ref={this.uploadedImage} style={{
                    display: 'block',
                    maxWidth: width,
                    maxHeight: height,
                    width: 'auto',
                    height: 'auto'
                }}/>
                <div style={{position: 'absolute', top: canvasTop, left: canvasLeft}}>
                    {detectionsArray.length ? this.renderDetectionSquare(imageDims.height, imageDims.width) : null}
                </div>
                <canvas id={"canvas-overlay"} style={{position: "absolute", top: canvasTop, left: canvasLeft}}/>
                <OverTimeButton style={{
                    position: "absolute",
                    bottom: 50,
                    right: 50,
                    backgroundColor: colors.black,
                    color: colors.white,
                    borderRadius: 0,
                    width: 150
                }}
                                onClick={this.toggleFaceLandmarks}>
                    {showFaceLandmarks ? getString("hide_landmarks") : getString("show_landmarks")}
                </OverTimeButton>
            </div>
        )
    }

    renderStreamPlayer = (width, height) => {

        const {detectionsArray, playerType, gotCheckupResults, showFaceLandmarks} = this.state
        let formattedHeight = null

        if (playerType === PLAYER_TYPE.CAMERA) {
            formattedHeight = height - OVERTIME_CHART_HEIGHT
        }

        if (playerType === PLAYER_TYPE.MARIO_JUMP) {
            formattedHeight = 150
        }

        let vid = document.getElementById('videoElement')
        let canvasLeft = 0;
        let canvasTop = 0;

        let vidDims = {}
        if (vid) {
            let {width, height} = videoDimensions(vid)
            canvasLeft = (vid.offsetWidth - width) / 2
            canvasTop = (vid.offsetHeight - height) / 2
            vidDims = {width, height, offsetWidth: vid.offsetWidth, offsetHeight: vid.offsetHeight}

        }

        return (
            <div style={{
                width: "100%", height: "100%", flexDirection: 'column',
                position: "relative", display: 'flex', alignItems: 'center',
            }}>
                <VideoStream
                    streamSuccessHandler={this.streamStarted}
                    width={width}
                    maxHeight={formattedHeight ? formattedHeight : "100vh"}
                    updateInput={this.updateInput}
                />
                <canvas id={"canvas-overlay"} style={{position: "absolute", top: canvasTop, left: canvasLeft}}/>

                <div style={{position: 'absolute', top: canvasTop, left: canvasLeft}}>
                    {detectionsArray.length && !(playerType === PLAYER_TYPE.CHECKUP_CAMERA && gotCheckupResults) ? this.renderDetectionSquare(vidDims.height, vidDims.width) : null}
                </div>

                {playerType === PLAYER_TYPE.CAMERA && this.renderOvertimeChart(width)}
                {playerType === PLAYER_TYPE.MARIO_JUMP && (<MarioJump/>)}
            </div>
        )
    }

    renderContentFeedback = (height) => {
        const {contentFeedbackUrl, contentFeedbackEnded} = this.state
        if(!contentFeedbackUrl) {
            return null
        }


    //    console.log("contentFeedbackUrl", contentFeedbackUrl)

        return (
            <MediaPlayer
                playing={this.state.contentFeedbackPlaying}
                url={contentFeedbackUrl}
                height={height}
                controls={!contentFeedbackEnded}
                onReady={(e) => {
                    this.playerRef = e['player'];
                    this.setState({contentFeedbackPlaying: true})
                }}
                onPlay={()=>{
                    console.log("onPlay")
                    this.setState({contentFeedbackPlaying: true}, ()=>{
                        if(!this.state.contentFeedbackEnded){
                            if(this.props.sdkUser){
                                soloSdkClient.setContent("content_feedback", true)
                            }
                            this.detectEmotions()

                        }

                    })
                }}
                onPause={()=>{
                    this.setState({contentFeedbackPlaying: false})
                    if(this.props.sdkUser){
                        soloSdkClient.stopMonitoring()
                    }
                }}
                onEnded={()=>{
                    this.setState({
                        contentFeedbackPlaying: false,
                        contentFeedbackEnded: true
                    })
                    if(this.props.sdkUser){
                        soloSdkClient.stopMonitoring()
                    }

                }}
                onError={()=>{
                    this.setState({contentFeedbackPlaying: false})
                    if(this.props.sdkUser){
                        soloSdkClient.stopMonitoring()
                    }
                }}
                onProgress={(stats)=>{
                    this.contentFeedbackTime = stats.playedSeconds
                   // console.log("onDuration", stats, this.contentFeedbackTime)
                }}

            />
        )
    }

    renderPlayer = () => {

        const {playerType} = this.state

        const width = this.player.current && this.player.current.offsetWidth
        let height = window.innerHeight

        if([PLAYER_TYPE.CONTENT_FEEDBACK, PLAYER_TYPE.MARIO_JUMP].includes(playerType)){
            height = 150
        }

        if(!this.state.emotionDetectionInitialized){
            return null
        }

        return (
            <div ref={this.player}
                 style={{
                     justifyContent: 'center',
                     alignItems: playerType === PLAYER_TYPE.VIDEO_UPLOAD ? 'flex-end' : 'center',
                     display: 'flex',
                     position: 'absolute',
                     top: 0,
                     bottom: 0,
                     right: 0,
                     left: 0,
                     margin: 'auto',
                 }}>
                { playerType === PLAYER_TYPE.IMAGE_UPLOAD
                    ? this.renderImageUpload(width, height)
                    : this.renderVideo(width, height)
                }


                <div style={{right: 20, bottom: OVERTIME_CHART_HEIGHT + 20, position: "absolute"}}>
                    {this.state.cplxModelActive ? this.renderChart() : this.state.avatarActive &&
                        <Avatar videoRef={this.avatarRef}/>}
                </div>

            </div>
        )
    }

    handleFaceIdMinDistanceChange = (e) => {
        this.changeThreshold("faceIdMinDistance", e.target.value)
    }

    changeLanguage = () => {
        const {currentLang} = this.state
        currentLang === "en" ? setLocale("ja") : setLocale("en")
        this.setState({currentLang: translations.selectedLocale})
    }

    currentLangVal = () => {
        let otherLocale = translations.supportedLanguages.find(l => l.key !== translations.selectedLocale);
        return otherLocale ? otherLocale.name : translations.fallbackLocale;
    }

    renderHeaderMenu = () => {
        const {time, fps} = this.state
        const {sdkUser, sdkHouse} = this.props
        return (
            <div ref={this.language}
                 style={{position: 'absolute', right: 15, top: 0, display: 'flex', flexDirection: "row"}}>

                <div>
                    <div>time: {time}</div>
                    <div>fps: {fps}</div>
                    <div>{sdkUser && sdkHouse ? `SDK: ${sdkHouse}` : ""}</div>
                </div>

                <div style={{
                    width: 69,
                    height: 45,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.white,
                    backgroundColor: colors.black,
                    borderRadius: "0 0 10px 10px",
                    cursor: 'pointer'
                }} onClick={() => {
                    window.open("https://www.loom.com/share/d748e1b965d4422d962ea0d636f84607?sid=9bb61e76-539f-4da6-bddb-f3e22b83620e", "_blank")
                }}>
                    {getString("tutorial")}
                </div>
                <div style={{
                    width: 69,
                    height: 45,
                    marginLeft: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: colors.white,
                    backgroundColor: colors.black,
                    borderRadius: "0 0 10px 10px",
                    cursor: 'pointer'
                }} onClick={this.changeLanguage}>
                    {this.currentLangVal()}
                </div>
                <img onClick={logout} style={{marginLeft: 10, cursor: "pointer"}}
                     src={getAsset("icons/logout.svg")}/>
            </div>
        )
    }

    calculateWidget = (value, level) => {
        const {currentlyDisplayed} = this.state
        const {widgetResultsArray} = this.props
        let current = widgetResultsArray.filter(w => w.id === currentlyDisplayed.id)
        let widget = {color: "#b0b0b0", value: 0, numeral: ""}

        if (current.length) {
            widget = _.cloneDeep(get(current[0], level, ""))
            let currentVal = get(current[0], value, 0);
            widget["numeral"] = Math.round(currentVal * 100)
        }

        return widget
    }

    renderWidgets = () => {
        const width = _.get(this.emotionsSidebar, "current.clientWidth", 179)

        const stressLevel = this.calculateWidget("stress", "stressLevel")
        const interestLevel = this.calculateWidget("interest", "interestLevel")
        const engagementLevel = this.calculateWidget("engagement", "engagementLevel",)
        const wellbeingLevel = this.calculateWidget("wellbeing", "wellbeingLevel")
        const energyLevel = this.calculateWidget("energy", "energyLevel")
        const valenceLevel = this.calculateWidget("valence", "valenceLevel")

        return (
            <div style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                top: 0,
                width: width,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
                marginTop: 44
            }}>
                {this.renderWidget(getString("mood"), valenceLevel.numeral, valenceLevel.value, valenceLevel.color, width)}
                {this.renderWidget(getString("energy"), energyLevel.numeral, energyLevel.value, energyLevel.color, width)}
                {/*   {this.renderWidget(getString("wellbeing"), wellbeingLevel.numeral, wellbeingLevel.value, wellbeingLevel.color, width)}*/}
                {this.renderWidget(getString("stress"), stressLevel.numeral, stressLevel.value, stressLevel.color, width)}
            </div>
        )
    }

    shouldRenderWidgets = () => {
        const {playerType} = this.state
        return playerType === PLAYER_TYPE.IMAGE_UPLOAD
    }
    renderWidget = (name, numeral, value, color, width) => {
        const {showEmotionsBar} = this.state
        return <Widget
            title={name}
            value={showEmotionsBar ? numeral : 0}
            progressColor={showEmotionsBar ? color : colors.warm_grey}
            presentationValue={showEmotionsBar ? getString(value) : null}
            width={width}
            grayOut={!showEmotionsBar}/>
    }

    setModelActiveState = (model, active) => {
        this.setState({[model]: active})
    }

    renderToggles = () => {
        const {
            optionsOpened,
            chartActive,
            sidebarActive,
            boundingBoxActive
        } = this.state

        if (!optionsOpened) {
            return null
        }



        return (
            <div style={{
                position: "absolute",
                bottom: OVERTIME_CHART_HEIGHT + 20,
                left: 10,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-end",
            }}>
                {/*<div>
                    <Switch checked={maskModelActive} onChange={(active) => {
                        setModelActiveState("maskModelActive", active)
                    }}></Switch>
                    <span>{getString("mask_model")}</span>

                </div>*/}
                {/* <div>
                    <Switch checked={emotionsModelActive} onChange={(active) => {
                        setModelActiveState("emotionsModelActive", active)
                    }}></Switch>
                    <span>{getString("emotions_model")}</span>
                </div>

                <div>
                    <Switch checked={yawnModelActive} onChange={(active) => {
                        setModelActiveState("yawnModelActive", active)
                    }}></Switch>
                    <span>{getString("yawn_model")}</span>
                </div>
                <div>
                    <Switch checked={eyesClosedModelActive} onChange={(active) => {
                        setModelActiveState("eyesClosedModelActive", active)
                    }}></Switch>
                    <span>{getString("eyes_closed_model")}</span>
                </div>
                <div>
                    <Switch checked={eyesDirectionModelActive} onChange={(active) => {
                        setModelActiveState("eyesDirectionModelActive", active)
                    }}></Switch>
                    <span>{getString("eyes_direction_model")}</span>
                </div>
                <div>
                    <Switch checked={chartActive} onChange={(active) => {
                        setModelActiveState("chartActive", active)
                    }}></Switch>
                    <span>{getString("chart")}</span>
                </div>
                <div>
                    <Switch checked={sidebarActive} onChange={(active) => {
                        setModelActiveState("sidebarActive", active)
                    }}></Switch>
                    <span>{getString("sidebar")}</span>
                </div>
                <div>
                    <Switch checked={boundingBoxActive} onChange={(active) => {
                        setModelActiveState("boundingBoxActive", active)
                    }}></Switch>
                    <span>{getString("bounding_box")}</span>
                </div>
*/}
                {process.env.REACT_APP_ENV=== "lab" ? (
                    <>
                        <SwitchWrapper>
                            <Switch checked={chartActive} onChange={(active) => {
                                this.setModelActiveState("chartActive", active)
                            }}></Switch>
                            <SwitchLabel>{getString("chart")}</SwitchLabel>
                        </SwitchWrapper>
                        <SwitchWrapper>
                            <Switch checked={sidebarActive} onChange={(active) => {
                                this.setModelActiveState("sidebarActive", active)
                            }}></Switch>
                            <SwitchLabel>{getString("sidebar")}</SwitchLabel>
                        </SwitchWrapper>
                        <SwitchWrapper>
                            <Switch checked={boundingBoxActive} onChange={(active) => {
                                this.setModelActiveState("boundingBoxActive", active)
                            }}></Switch>
                            <SwitchLabel>{getString("bounding_box")}</SwitchLabel>
                        </SwitchWrapper>
                        <SwitchWrapper>
                            <Switch checked={this.state.avatarActive} onChange={(active) => {
                                this.toggleAvatar()
                            }}></Switch>
                            <SwitchLabel>{getString(this.state.avatarActive ? "disable_avatar" : "enable_avatar")}</SwitchLabel>
                        </SwitchWrapper>
                    </>
                ) : null
                }



                <SwitchWrapper>
                    <Switch checked={this.state.emojiFilterActive} onChange={(active) => {
                        this.toggleEmoji()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.emojiFilterActive ? "disable_emoji" : "enable_emoji")}</SwitchLabel>
                </SwitchWrapper>

                <SwitchWrapper>
                    <Switch checked={this.state.cplxModelActive} onChange={(active) => {
                        this.toggleCplxModel()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.cplxModelActive ? "disable_cplxModel" : "enable_cplxModel")}</SwitchLabel>
                </SwitchWrapper>

                <SwitchWrapper>
                    <Switch checked={this.state.alertsActive} onChange={(active) => {
                        this.toggleAlerts()
                    }}></Switch>
                    <SwitchLabel>{getString(this.state.alertsActive ? "disable_alerts" : "enable_alerts")}</SwitchLabel>
                </SwitchWrapper>
            </div>
        )
    }

    render() {

        const {
            loader,
            sidebarActive,
            contentFeedbackPopupOpen,
        } = this.state
        return (
            <Container fluid style={{height: "100%", position: "relative"}}>
                {!loader &&
                    (<Row style={{height: "100%"}}>
                        <Col md={2} ref={this.emotionsSidebar} style={{paddingRight: 0}}>
                            {sidebarActive && this.renderEmotionsBar()}
                        </Col>
                        <Col md={10}>
                            {this.renderPlayer()}
                            <Logo/>
                            {this.renderHeaderMenu()}
                            {this.renderOptions()}
                            {this.shouldRenderWidgets() && this.renderWidgets()}
                            {this.renderToggles()}
                        </Col>
                    </Row>)}

                <ContentSelectionPopup isOpen={contentFeedbackPopupOpen} closeHandler={(url)=>{
                    if(url){
                        this.setState({
                            contentFeedbackPopupOpen: false,
                            contentFeedbackUrl: url,
                            playerType: PLAYER_TYPE.CONTENT_FEEDBACK
                        })
                    }else {
                        this.setState({
                            contentFeedbackPopupOpen: false
                        })
                    }

                }} />
            </Container>
        );
    }
}

const mapStateToProps = ({emotionalData, engine}) => ({
    overtime: emotionalData.overtime,
    widgetResultsArray: emotionalData.widgetResultsArray,
    marioReady: engine.play,
    marioReloading: engine.reloading,
});

const mapDispatchToProps = {
    pushToOvertime,
    resetOvertime,
    setWidgetResults,
    marioJumping,
    setReady,
    setDie
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const OverTimeButton = styled.button`
  background: #909090;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding-left: 8px;
  padding-right: 8px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // text-transform: uppercase;
  width: 98px;
  height: 23px;

  color: #FFFFFF;
  cursor: pointer;
  border: 0;
  outline: 0;
`

const SwitchWrapper = styled.div`
background: #fff;
border-radius: 10px;
padding: 3px;
  display: flex;
  font-size: 18px;
  margin-top: 5px;
`

const SwitchLabel = styled.span`
margin-left: 5px;
`
