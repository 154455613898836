import React from 'react';
import firebase, {loginValidation} from "./api/api"
import {initMixpanel, identifyMixpanel} from "./utils/analytics";
import {solo as soloSdkClient} from "./api/solo-sdk";

import {useEffect, useState} from 'react'

import Login from "./views/Login"

import Demo from "./views/Demo"
import { store } from './app/store'
import { Provider } from 'react-redux'


import './App.css';

function App({}) {


    const [screen, setScreen] = useState("LOGIN");
    const [screenReady, setScreenReady] = useState(false);

    const [user, setUser] = useState(null)
    const [sdkUser, setSdkUser] = useState(false)
    const [sdkHouse, setSdkHouse] = useState(null)


    useEffect(() => {
        initMixpanel()
        const handleAuth = async () => {
            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                    user.getIdToken(true).then(async (idToken) => {
                        localStorage.setItem("checkupUserToken", idToken)
                        localStorage.setItem("userToken", idToken)
                        console.log("user", user)
                        identifyMixpanel(user.uid, user.email)

                        try {
                            //login validation - get sdk credentials
                            let res = await loginValidation({idToken})
                            console.log("loginValidation", res)

                            if(res && res.success){
                                if(res.sdkCreds){
                                    //todo init sdk
                                    let sdkInit = await soloSdkClient.init({appId: res.sdkCreds.appId, apiKey: res.sdkCreds.apiKey})
                                    console.log("sdkInit", sdkInit)
                                    if(!sdkInit){
                                        console.error("sdk init failed", res.sdkCreds)
                                        setScreen("LOGIN")
                                        return
                                    }

                                    soloSdkClient.identify({
                                        userId: res.selectedHouse.id,
                                        groupId: res.selectedHouse.id,
                                        userName: `${res.selectedHouse.name} Playground`,
                                        groupName: res.selectedHouse.name,
                                    })
                                    setSdkUser(true)
                                    setSdkHouse(res.selectedHouse.name)

                                }
                                setScreen("DEMO")
                            }else {
                                console.error("loginValidation error", res)
                                setScreen("LOGIN")
                            }
                        }catch (e) {
                            console.error("loginValidation error", e)
                            setScreen("LOGIN")
                        }
                    });
                }
            });

            firebase.auth().onIdTokenChanged(async (user) => {
                if (user) {
                    // User is signed in or token was refreshed.
                    const idToken = await user.getIdToken();
                    localStorage.setItem("checkupUserToken", idToken)
                    localStorage.setItem("userToken", idToken)
                } else {
                    // User is signed out.
                    setUser(null)
                    // todo: move to logout
                    setScreen("LOGIN")
                }
                setScreenReady(true)
            })
        }


        handleAuth().catch(console.error);
    }, [])



    const renderScreen = () => {
        switch (screen) {
            case "LOGIN":
                return <Login/>
            case "DEMO":
                return <Demo sdkUser={sdkUser} sdkHouse={sdkHouse}/>
            default:
                return <div>screen not found</div>
        }
    }

    return screenReady ? renderScreen() : (<Demo/>)

}

export default (App);

