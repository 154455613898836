import * as tf from '@tensorflow/tfjs';
import * as automl from '@tensorflow/tfjs-automl';
import * as faceapi from 'face-api.js';
import {detectFrown, detectHeadPose, detectYawnFromLandmarks} from "../index";
import {FACE_SIMILARITY_THRESHOLD} from "../../../api/remoteConfig";

tf.setBackend('webgl');

let MODELS_DIR = process.env.PUBLIC_URL + '/models';
let streamInterval
let maskModel = null
let yawningModel = null
let closedEyesModel = null
let canvas = document.createElement('canvas');
let yawningCanvas = document.createElement('canvas');
let eyesCanvas = document.createElement('canvas');

function getCroppedImageData(input, x, y, width, height, canvas) {
    // create a canvas
    canvas.width = input.width;
    canvas.height = input.height;

    let context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    // draw the video frame to the canvas
    context.drawImage(input, x, y, width, height, 0, 0, width, height);
    ///context.drawImage(input, x, y, width, height, 0, 0, width, height);

    // get the cropped image data
 //   let imageData = context.getImageData(0,0, canvas.width, canvas.height);

    // return the cropped image data
    return canvas
}
export const initEmotionDetection = async () => {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(MODELS_DIR)
    await faceapi.nets.tinyFaceDetector.loadFromUri(MODELS_DIR)
    await faceapi.nets.faceExpressionNet.loadFromUri(MODELS_DIR)
    await faceapi.nets.faceLandmark68Net.loadFromUri(MODELS_DIR)

    await faceapi.loadFaceRecognitionModel(MODELS_DIR)
        //await automl.loadImageClassification(`${MODELS_DIR}/mask/model.json`);
  //  maskModel = await automl.loadImageClassification(`${MODELS_DIR}/mask/model.json`);
  // console.log("maskModel", maskModel)
    //yawningModel = await automl.loadImageClassification(`${MODELS_DIR}/yawn_model/model.json`);

    closedEyesModel = await automl.loadImageClassification(`${MODELS_DIR}/eyes_closed/model.json`);

    console.log("Models loaded", faceapi.nets)


    return true
}

const detectFaceMask = async function(input, detections, modelsConfig) {
    // Create a list to store the results
    let results = [];

    const {
        maskModelActive,
        yawnModelActive,
        eyesClosedModelActive,
        eyesDirectionModelActive
    } = modelsConfig

    try{
      //  console.time("detectSoloModels")

        // tf.engine().startScope();
        for (let i = 0; i < detections.length; i++) {
            // Create an image tensor from the detected face

            let box = detections[i].detection.box

          /*  if(maskModelActive){
                console.time("detectMask")
                let faceCanvas = getCroppedImageData(input, box.left, box.top, box.width, box.height, canvas);
                const predictions = await maskModel.classify(faceCanvas);
                //    console.log("predictions", predictions)
                detections[i].mask = predictions[0].prob
                detections[i].noMask = predictions[1].prob
                console.timeEnd("detectMask")
            }*/

            if (yawnModelActive) {
             //   console.time("detectYawn")
              //  let widthMargin = box.width * 0.2
              //  let heightMargin = box.height * 0.25
             //   let yawnCanvas = getCroppedImageData(input, box.left- widthMargin / 2, box.top- heightMargin / 2, box.width+widthMargin, box.height+ heightMargin, yawningCanvas);
              //  showOnTestCanvas(yawnCanvas, "test-canvas2")
               // let yawnPredictions = await yawningModel.classify(yawnCanvas);
                //       console.log("yawnPredictions", yawnPredictions)
               // detections[i].yawn = yawnPredictions[1].prob
               // detections[i].noYawn = yawnPredictions[0].prob

             //   console.timeEnd("detectYawn")

                let ratio = detectYawnFromLandmarks(detections[i].landmarks.positions)
                detections[i].yawnRatio = ratio

                //const headPose = faceapi.getHeadPose(detection[i].landmarks);
               // console.log('Head Pose:', headPose);
                let headPose = detectHeadPose(detections[i].landmarks.positions)
              //  console.log("headPose", headPose)
                detections[i].headPose = headPose

                let frown = detectFrown(detections[i].landmarks.positions, box)
                detections[i].frown = frown
            }


        if (eyesClosedModelActive) {
        //    console.time("detectClosedEyes")
            let eyesPosition = getEyesCropCoordinates(detections[i].landmarks.positions)
            let widthMargin = eyesPosition.leftEye.width * 0.1
            let heightMargin = eyesPosition.leftEye.height * 0.1
            let leftEyeCanvas = getCroppedImageData(input, eyesPosition.leftEye.left - widthMargin / 2, eyesPosition.leftEye.top- heightMargin / 2, eyesPosition.leftEye.width+widthMargin, eyesPosition.leftEye.height+ heightMargin, eyesCanvas);
           // let leftEyeCanvas = getCroppedImageData(input, eyesPosition.leftEye.left, eyesPosition.leftEye.top, eyesPosition.leftEye.width, eyesPosition.leftEye.height, eyesCanvas);
          //  showOnTestCanvas(leftEyeCanvas, "eyes-canvas2")
            let closedLeftEyePredictions = await closedEyesModel.classify(leftEyeCanvas);
            //    console.log("closedLeftEyePredictions", closedLeftEyePredictions)
            detections[i].closedLeftEye = closedLeftEyePredictions[0].prob
            detections[i].openLeftEye = closedLeftEyePredictions[1].prob

            let rightEyeCanvas = getCroppedImageData(input, eyesPosition.rightEye.left - widthMargin / 2, eyesPosition.rightEye.top - heightMargin / 2, eyesPosition.rightEye.width, eyesPosition.rightEye.height + heightMargin, eyesCanvas);
        //    showOnTestCanvas(rightEyeCanvas, "eyes-canvas")
            let closedRightEyePredictions = await closedEyesModel.classify(rightEyeCanvas);
            //   console.log("closedRightEyePredictions", closedRightEyePredictions)
            detections[i].closedRightEye = closedRightEyePredictions[0].prob
            detections[i].openRightEye = closedRightEyePredictions[1].prob

            detections[i].closedEyes = (detections[i].closedLeftEye + detections[i].closedRightEye) / 2
            detections[i].openEyes = (detections[i].openLeftEye + detections[i].openRightEye) / 2
       //     console.timeEnd("detectClosedEyes")
        }


         //   results.push(predictions)
        }

       // console.timeEnd("detectSoloModels")

        return results;
    }catch (e) {
        console.log("Error detectFaceMask", e)
        throw e
    }

}

function getEyesCropCoordinates(landmarks) {
    const leftEyeStartIndex = 36;
    const rightEyeStartIndex = 42;
    const landmarksPerEye = 6;

    const leftEyeLandmarks = landmarks.slice(
        leftEyeStartIndex,
        leftEyeStartIndex + landmarksPerEye
    );
    const rightEyeLandmarks = landmarks.slice(
        rightEyeStartIndex,
        rightEyeStartIndex + landmarksPerEye
    );

    // Calculate the left, top, width, and height for the left eye square
    const leftEyeLeft = Math.min(...leftEyeLandmarks.map((landmark) => landmark.x)) - 20;
    const leftEyeTop = Math.min(...leftEyeLandmarks.map((landmark) => landmark.y)) - 20;
    const leftEyeWidth = Math.max(...leftEyeLandmarks.map((landmark) => landmark.x)) - leftEyeLeft + 40;
    const leftEyeHeight = Math.max(...leftEyeLandmarks.map((landmark) => landmark.y)) - leftEyeTop + 40;

    // Calculate the left, top, width, and height for the right eye square
    const rightEyeLeft = Math.min(...rightEyeLandmarks.map((landmark) => landmark.x)) - 20;
    const rightEyeTop = Math.min(...rightEyeLandmarks.map((landmark) => landmark.y)) - 20;
    const rightEyeWidth = Math.max(...rightEyeLandmarks.map((landmark) => landmark.x)) - rightEyeLeft + 40;
    const rightEyeHeight = Math.max(...rightEyeLandmarks.map((landmark) => landmark.y)) - rightEyeTop + 40;

    return {
        rightEye: {
            left: leftEyeLeft,
            top: leftEyeTop,
            width: leftEyeWidth,
            height: leftEyeHeight,
        },
        leftEye: {
            left: rightEyeLeft,
            top: rightEyeTop,
            width: rightEyeWidth,
            height: rightEyeHeight,
        },
    };
}

export const detectEmotions = async (input, options = options, modelsConfig) => {
    // console.log("detectEmotionsinput", input)

    //console.time("detectEmotions")
    let detectionsWithExpressions = await faceapi.detectAllFaces(input, options).withFaceLandmarks().withFaceDescriptors().withFaceExpressions()
  //  console.timeEnd("detectEmotions")

    let maskData = await detectFaceMask(input, detectionsWithExpressions, modelsConfig)



    return detectionsWithExpressions;
}

export const uploadImage = async () => {
    const imgFile = document.getElementById('imageUpload').files[0]
    const img = await faceapi.bufferToImage(imgFile)
    document.getElementById('myImage').src = img.src
}
// Initialize the faceIdMap to store face IDs and their descriptors
export const faceIdMap = new Map();
// Function to find a matching face ID based on face descriptors
export function findMatchingFaceID(descriptor) {
    // Iterate through the faceIdMap to find a matching face ID
    for (const [faceID, storedDescriptor] of faceIdMap.entries()) {
        // Calculate the similarity between the current descriptor and the stored descriptor
        const distance = faceapi.euclideanDistance(descriptor, storedDescriptor);
        const similarityThreshold = FACE_SIMILARITY_THRESHOLD; // Adjust the threshold based on your requirements

       // console.log("similarity distance", distance)

        // If the distance is below the similarity threshold, consider it a match and return the face ID
        if (distance < similarityThreshold) {
            return faceID;
        }
    }

    // If no matching face ID is found, return null
    return null;
}

export function filterFaceIdMap(facesToKeep) {
    for (const [faceID, storedDescriptor] of faceIdMap.entries()) {
        if (!facesToKeep.includes(faceID)) {
            faceIdMap.delete(faceID);
        }
    }
}

export function findMatchingFaceIDLocationBased(box, threshold) {
 //   console.log("findMatchingFaceIDLocationBased", box)
    // Iterate through the faceIdMap to find a matching face ID
    let matchedFace = null;
    let closestDistance = Infinity;
    for (const [faceID, storedBox] of faceIdMap.entries()) {
        // Calculate the similarity between the current descriptor and the stored descriptor
        const distance = Math.sqrt(
            (box.x - storedBox.x) ** 2 + (box.y - storedBox.y) ** 2
        );

        let minDistance = threshold || 50

        if (distance <= minDistance && distance < closestDistance) {
            matchedFace = faceID;
            closestDistance = distance;
        }

        if(!matchedFace && distance > minDistance) {
            console.log("distance", distance)
        }
    }

    return matchedFace;
}

let currentFaceID = 0;

// Function to generate a random face ID
export function generateFaceID() {
    currentFaceID += 1;
    return currentFaceID // You can adjust the length of the ID if needed
}
