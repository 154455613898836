export const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID

};

export const translationsFirebaseConfig = {
    apiKey: process.env.REACT_APP_TRANSLATIONS_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_TRANSLATIONS_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_TRANSLATIONS_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_TRANSLATIONS_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_TRANSLATIONS_FIREBASE_MEASURMENT_ID
};

let apiUrl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "http://localhost:8080" : 'https://practical-theme-289410.uc.r.appspot.com';//process.env.REACT_APP_API_URL;

export const apiRoutes = {
    getFirstSong: apiUrl + "/api/songs",
    getSongsMetadata: apiUrl + "/api/songs/metadata",
    getPlaylist: apiUrl + "/api/get-playlist",
    logSessionEvent: apiUrl + "/api/log-session-result",
    houseLogin: apiUrl + "/api/house/login",
    getResidents: apiUrl + "/api/house/residents",
    loginValidation: apiUrl + "/api/dashboard/loginValidation",
}

export const GUEST_HOUSE_KEY = "gusets"
