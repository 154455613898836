import React, { Component } from 'react';
import styled from 'styled-components'
import ProgressBar from './ProgressBar'
import colors from '../styles/colors';
import _ from 'lodash'
import { Fragment } from 'react';
import { getString } from "../utils/translations";
import {MASK_ON_CERTAINTY_THRESHOLD, YAWN_CERTAINTY_THRESHOLD} from "../api/remoteConfig";
import EmojiFilter from "./EmojiFilter";
import {formatMiliseconds} from "../views/Demo/helpers";



class DetectionSquare extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
        }
    }

    toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    renderExtension = () => {

        const { stressLevel, engagementLevel, font, mask,
            noMask, yawn, closedEyes, alerts, noYawn, yawnRatio, headPose, frown, id,
            closedRightEye,
            closedLeftEye, openEyes,
            showId, score, alertsActive
        } = this.props
     //   console.log("mask", mask, MASK_ON_CERTAINTY_THRESHOLD)

     //   console.log("DetectionSquare headPose", headPose)
        let tirednessAlert = false
        let asleepAlert = false
        let totalYawnTime = 0
        let leftEyeClosedTimeInARow = 0
        let rightEyeClosedTimeInARow = 0
        let blinkCount = 0
        let avgClosedEyesTime = 0
        let bpm = 0
        let bpts = 0
        let focusTime = 0
        if(alerts){
            //console.log("alerts", alerts)
            tirednessAlert = alerts.tiredness.tired
            totalYawnTime = alerts.tiredness.totalYawnTime
            asleepAlert = alerts.asleep.asleep
            leftEyeClosedTimeInARow = alerts.asleep.leftEyeClosedTimeInARow
            rightEyeClosedTimeInARow = alerts.asleep.rightEyeClosedTimeInARow
            avgClosedEyesTime = alerts.asleep.avgClosedEye
            blinkCount = alerts.alert.blinkCount
            bpm = alerts.alert.bpm
            bpts = alerts.alert.bpts
            focusTime = alerts.alert.focusTime
        }

        return (<Fragment>
            {showId && (<MeterContainer>
                <Title font={font ? "15" : "10"}>
                    {getString("id")}
                </Title>
                <Value font={font ? "15" : "10"}>
                    {id}
                </Value>
            </MeterContainer>)}

           {/* <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    {getString("stress")}
                </Title>
                <Value color={stressLevel && stressLevel.color} font={font ? "15" : "10"}>
                    {getString(stressLevel && stressLevel.value)}
                </Value>
            </MeterContainer>*/}

     {/*       <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    {mask > MASK_ON_CERTAINTY_THRESHOLD ? getString("mask") : getString("noMask")}
                </Title>
                <Value color={engagementLevel && engagementLevel.color} font={font ? "15" : "10"}>
                    {mask > MASK_ON_CERTAINTY_THRESHOLD ? `${Math.round((mask + Number.EPSILON) * 100) / 100}` : `${Math.round((noMask + Number.EPSILON) * 100) / 100}`}
                </Value>

            </MeterContainer>*/}

            <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("score")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {Math.round((score + Number.EPSILON) * 100) / 100}
                </Value>

            </MeterContainer>

           {/* <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("yawnRatio")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {Math.round((yawnRatio + Number.EPSILON) * 100) / 100}
                </Value>

            </MeterContainer>*/}
            {process.env.REACT_APP_ENV=== "lab" ? (
                <>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("blinks_per_minute")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {Math.round((bpm + Number.EPSILON) * 100) / 100}
                        </Value>

                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("blinks_per_10_s")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {Math.round((bpts + Number.EPSILON) * 100) / 100}
                        </Value>

                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("focusTime")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {formatMiliseconds(focusTime)}
                        </Value>

                    </MeterContainer>
                </>
            ) : null}
         {/*   <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("blinkCount")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {blinkCount}
                </Value>

            </MeterContainer>*/}
         {/*   <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("closed_eyes_time")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {Math.round((avgClosedEyesTime / 1000 + Number.EPSILON) * 100) / 100}
                </Value>
            </MeterContainer>*/}

            {/*<MeterContainer style={tirednessAlert ? {background: colors.red} : null}>
                <Title font={font ? "15" : "10"}>
                    { getString("total_yawn_time")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {Math.round((totalYawnTime / 1000 + Number.EPSILON) * 100) / 100}
                </Value>
            </MeterContainer>*/}

          {/*  <MeterContainer style={asleepAlert ? {background: colors.red} : null}>
                <Title font={font ? "15" : "10"}>
                    { getString("left_eye_closed_time")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {closedLeftEye.toFixed(2)}
                </Value>
            </MeterContainer>

            <MeterContainer style={asleepAlert ? {background: colors.red} : null}>
                <Title font={font ? "15" : "10"}>
                    { getString("right_eye_closed_time")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {closedRightEye.toFixed(2)}
                </Value>
            </MeterContainer>
*/}

       {/*     <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("blink_count")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {blinkCount}
                </Value>
            </MeterContainer>*/}



           {/* {headPose && (
                <>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("head_pose_horizontal")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {headPose.horizontal}
                        </Value>
                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("head_pose_vertical")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {headPose.vertical}
                        </Value>
                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("head_pose_tilt")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {headPose.tilt}
                        </Value>
                    </MeterContainer>

                </>
            )}*/}
           {/* <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("eyebrowToEyeTop")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {frown.eyebrowToEyeTop.toFixed(2)}
                </Value>
            </MeterContainer>
            <MeterContainer>
                <Title font={font ? "15" : "10"}>
                    { getString("eyeWidth")}
                </Title>
                <Value color={colors.black} font={font ? "15" : "10"}>
                    {frown.eyeWidth.toFixed(2)}
                </Value>
            </MeterContainer>*/}
            {/*{frown ? (
                <>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("eyebrowEyeRatio")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {frown.eyebrowEyeRatio.toFixed(2)}
                        </Value>
                    </MeterContainer>
                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("noseEyebrowRatio")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {frown.noseEyebrowRatio.toFixed(2)}
                        </Value>
                    </MeterContainer>

                    <MeterContainer>
                        <Title font={font ? "15" : "10"}>
                            { getString("frownScore")}
                        </Title>
                        <Value color={colors.black} font={font ? "15" : "10"}>
                            {frown.frownScore.toFixed(2)}
                        </Value>
                    </MeterContainer>
                </>

            ) : null}*/}
        </Fragment>)
    }

    renderAlert = () => {
        const { alerts } = this.props
        let alert = alerts && (alerts.alert.yawning || alerts.alert.highStress || alerts.alert.eyesClosed || !alerts.alert.headFacingCamera)
        let alertString = ""
        if(alert){
            if(alerts.alert.yawning){
                alertString = "alert_tired"
            }else if(alerts.alert.highStress){
                alertString = "alert_stress"
            }else if(alerts.alert.eyesClosed){
                alertString = "alert_asleep"
            }else if(!alerts.alert.headFacingCamera){
                alertString = "alert_attention"
            }
        }
        if(alert){
            return (
                <div style={{position: "absolute", top: -30, left: 0, color: "#fff", font: "OpenSans", fontWeight: 800}}>
                    {getString(alertString)}
                </div>
            )
        }
    }

    render() {

        let marginLeft = null

        const { top, left, width, height, label, percentage, progressColor, emphasize, extendedDetectionSquare, font,emojiFilter, alerts, alertsActive } = this.props
        const { open } = this.state
        if (width < 100) {
            marginLeft = (100 - width) / -2
        }

        let alert = alertsActive && alerts && (alerts.alert.yawning || alerts.alert.highStress || alerts.alert.eyesClosed || !alerts.alert.headFacingCamera)
        //console.log("alert", alert, alerts)


        return (
            <div style={{ position: 'absolute', top, left, zIndex: 999 }}>
                {alertsActive && this.renderAlert()}
                <div style={{ width, height }} onClick={this.toggle}>
                    <Square height={height} color={alert ? colors.red : emphasize ? colors.blue : colors.white} />
                </div >
                {extendedDetectionSquare && open &&
                    <div style={{ width: '100%', minWidth: 125, position: 'absolute', marginLeft, top: height }}>
                        <div style={{width: "100%"}} >
                            <ProgressBar
                                label={label}
                                percentage={percentage}
                                progressColor={progressColor}
                                backgroundColor={"white"}
                                width={63}
                                font={font ? true : false}
                                showValue={true}
                                heightMargin={true}
                            />
                        </div>
                        {extendedDetectionSquare && this.renderExtension()}
                    </div>
                }
            </div >

        );
    }
}

DetectionSquare.defaultProps = {
    showId: true
}

const Square = styled.div`
    // width: 100%;
    height: ${props => props.height}px;
    border: 3px solid ${props => props.color};
    box-sizing: border-box;
`

const MeterContainer = styled.div`
    display: flex;
    background: white;
    height: 18px;
    position: relative;
`

const Title = styled.div`
    font-size: ${props => props.font}px;
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    bottom: 0;
    top: 0;
    margin-left: 5px;
    font-weight: 900;
`

const Value = styled.div`
    font-size: ${props => props.font}px;
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    top: 0;
    margin-right: 5px;
    color: ${props => props.color};
`

export default DetectionSquare;
