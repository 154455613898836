import { createSlice } from '@reduxjs/toolkit'
import {PLAYER_TYPE} from "../../constants";
import {formatMiliseconds, formatTime, normalizeValue} from "../../views/Demo/helpers";
import _ from "lodash";
import {detectBlink, isHeadFacingCamera} from "../../utils/bioFeedback";

const initialState = {
    overtime: [],
    overtimeById: {},
    widgetResultsArray: [],
    widgetResults: {},
    alertsData: {},
    overtimeHistory: [],
}

export const createAlertTemplate = () => {
    return{
        totalYawnTime: 0,
        leftEyeClosedTimeInARow: 0,
        rightEyeClosedTimeInARow: 0,
        blinkCount: 0,
        headFacingCamera: true,
        yawning: false, // mouth is open over X seconds
        highStress: false, // stress is over X
        eyesClosed: false, // eyes are closed over X seconds
        focusTime: 0

    }
}

export const emotionalDataSlice = createSlice({
    name: 'emotionalData',
    initialState,
    reducers: {
        pushToOvertime: (state, action) => {
            const {data, playerType, vidCurrTime, timeSpent} = action.payload

            let updatedOvertime = _.cloneDeep(state.overtime)
            let index;
            if ([PLAYER_TYPE.VIDEO_UPLOAD, PLAYER_TYPE.CONTENT_FEEDBACK].includes(playerType)) {
                index = formatTime(vidCurrTime)
            } else {
                index = formatMiliseconds(timeSpent);
            }

            let items =[]
             let item = {}
            let generalItem = {}

             for(let i = 0; i < data.length; i++) {
                 let d = data[i]

             //    console.log("d", d.patterns)

                 let yawnRatio = Math.round(normalizeValue(d.yawnRatio) * 100);
                 let eyebrowEyeRatio =((d.frown ? d.frown.eyebrowEyeRatio : 0) / 2.5) * 100;
                 let frownScore = (d.frown ? d.frown.frownScore : 0) - 200;
                 let pitchAngle = d.headPose ? d.headPose.pitchAngle * 100 : 0
                 let noseEyebrowRatio = d.frown ? d.frown.noseEyebrowRatio : 0;
                 let yawAngle = (d.headPose ? d.headPose.yawAngle : 0);


                 if (yawnRatio > 100) {
                     yawnRatio = 100
                 }

                 // normalize eyebrow eye ratio
                 if (eyebrowEyeRatio < 50) {
                     eyebrowEyeRatio = 50
                 }else if (eyebrowEyeRatio > 200) {
                     eyebrowEyeRatio = 200
                 }

                 if(pitchAngle > 90){
                     pitchAngle = 90
                 }else if(pitchAngle < -90){
                     pitchAngle = -90
                 }


                 if (frownScore > 100) {
                     frownScore = 100
                 } else if (frownScore < 0) {
                     frownScore = 0
                 }

                 if (pitchAngle > 90) {
                     pitchAngle = 90
                 } else if (pitchAngle < -90) {
                     pitchAngle = -90
                 }

                 if(noseEyebrowRatio > 350){
                     noseEyebrowRatio = 350
                 }else if(noseEyebrowRatio < 300){
                     noseEyebrowRatio = 300
                 }


                 item = {
                     ...item,
                     index,
                     timeSpent: timeSpent,
                     id: d.id,
                     [`mood_${d.id}`] : Math.round(normalizeValue(d.valence) * 100),
                     [`energy_${d.id}`]: Math.round(normalizeValue(d.energy) * 100),
                     [`wellbeing_${d.id}`]: Math.round(normalizeValue(d.wellbeing) * 100),
                     [`engagement_${d.id}`]: Math.round(normalizeValue(d.engagement) * 100),
                     [`interest_${d.id}`]: Math.round(normalizeValue(d.interest) * 100),
                     [`stress_${d.id}`]: Math.round(normalizeValue(d.stress) * 100),
                     [`closedEyes_${d.id}`]: Math.round(normalizeValue(d.closedEyes) * 100),
                     [`yawn_${d.id}`]: Math.round(normalizeValue(d.yawn) * 100),
                     [`yawnRatio_${d.id}`]: yawnRatio,
                     [`mask_${d.id}`]: Math.round(normalizeValue(d.mask) * 100),
                     [`noMask_${d.id}`]: Math.round(normalizeValue(d.noMask) * 100),
                     [`closedLeftEye_${d.id}`]: Math.round(normalizeValue(d.closedLeftEye) * 100),
                     [`openLeftEye_${d.id}`]: Math.round(normalizeValue(d.openLeftEye) * 100),
                     [`closedRightEye_${d.id}`]: Math.round(normalizeValue(d.closedRightEye) * 100),
                     [`openRightEye_${d.id}`]: Math.round(normalizeValue(d.openRightEye) * 100),
                     [`openEyes_${d.id}`]: Math.round(normalizeValue(d.openEyes) * 100),
                     [`yawAngle_${d.id}`]: yawAngle,
                     [`pitchAngle_${d.id}`]: pitchAngle,
                     [`eyebrowEyeRatio_${d.id}`]: eyebrowEyeRatio,
                     [`frownScore_${d.id}`]: frownScore,
                     [`noseEyebrowRatio_${d.id}`]: noseEyebrowRatio,
                 }

                 generalItem ={
                     ...item,
                     index,
                     timeSpent: timeSpent,
                     id: d.id,
                     [`mood`] : Math.round(normalizeValue(d.valence) * 100),
                     [`energy`]: Math.round(normalizeValue(d.energy) * 100),
                     [`wellbeing`]: Math.round(normalizeValue(d.wellbeing) * 100),
                     [`engagement`]: Math.round(normalizeValue(d.engagement) * 100),
                     [`interes}`]: Math.round(normalizeValue(d.interest) * 100),
                     [`stress`]: Math.round(normalizeValue(d.stress) * 100),
                     [`closedEyes`]: Math.round(normalizeValue(d.closedEyes) * 100),
                     [`yawn`]: Math.round(normalizeValue(d.yawn) * 100),
                     [`yawnRatio`]: yawnRatio,
                     [`mask`]: Math.round(normalizeValue(d.mask) * 100),
                     [`noMask`]: Math.round(normalizeValue(d.noMask) * 100),
                     [`closedLeftEye`]: Math.round(normalizeValue(d.closedLeftEye) * 100),
                     [`openLeftEye`]: Math.round(normalizeValue(d.openLeftEye) * 100),
                     [`closedRightEye`]: Math.round(normalizeValue(d.closedRightEye) * 100),
                     [`openRightEye`]: Math.round(normalizeValue(d.openRightEye) * 100),
                     [`openEyes`]: Math.round(normalizeValue(d.openEyes) * 100),
                     [`yawAngle`]: yawAngle,
                     [`pitchAngle`]: pitchAngle,
                     [`eyebrowEyeRatio`]: eyebrowEyeRatio,
                     [`frownScore`]: frownScore,
                     [`noseEyebrowRatio`]: noseEyebrowRatio,
                     frown: {
                         ...item.frown,
                         eyebrowEyeRatio,
                         frownScore,
                         noseEyebrowRatio,
                     }
                 }

                // console.log("overtime item", generalItem.eyebrowEyeRatio)

                 if(state.overtimeById[d.id]) {
                     state.overtimeById[d.id].push(generalItem)
                 }else{
                     state.overtimeById[d.id] = [generalItem]
                 }

                 let blink = detectBlink(state.overtimeById[d.id])
                 state.overtimeById[d.id][state.overtimeById[d.id].length - 1].blink = blink


             }
             updatedOvertime.push(item)

            state.overtime = updatedOvertime;

        },
        resetOvertime: (state)  => {
            state.overtimeHistory.push(_.cloneDeep(state.overtime))
            state.overtime = []
        },
        setWidgetResults: (state, action) => {
            state.widgetResultsArray = action.payload
        },
        setAlertsData: (state, action) => {
            state.alertsData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { pushToOvertime,resetOvertime ,setWidgetResults,setAlertsData} = emotionalDataSlice.actions

export default emotionalDataSlice.reducer
