import "./KeyMessage.css";
import React from "react";
const PressAnyKey = () => {
  return (
    <div className="press-container">
        <p className="press-title">PRESS ENTER KEY TO START THE GAME</p>
        <p className="press-subtitle">SMILE TO JUMP!</p>
    </div>
  )
}
export default PressAnyKey
